import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Divider, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, NativeSelect, RadioGroup, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import LoaderButton from '../LoaderButton';
import BasicDatePicker from '../DatePicker';
import AppDropDown from '../AppDropDown';
import APP_HTTP from '../../APP_HTTP';
import { handleSetPaymentInfo, handleToggleEditPayment, handleToggleViewPayment } from './Action';
import { handleChangeMenu } from '../../actions/Action';
import Autocomplete from '@mui/material/Autocomplete';
import Sections from '../settings/sections/Sections';
import Classes from '../settings/classes/Classes';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Close, CurrencyRupee, Delete, Edit } from '@mui/icons-material';
import AppUtils from '../../AppUtils';
import PaymentTypes from '../settings/payment-types/PaymentTypes';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CreatePayment = (props)=> {
    let paymentDataInitialState = {
        update_id : 0,
        payment_sequence : '',
        payment_type : '',
        payment_date : '',
        payment_contact : '',
        list_item : '',
    }
    let paymentItemListInitialState = {
        payment_meta_id:0,
        payment_mode:1,
        particulars:'',
        particulars_name:'',
        reference:'',
        amount:'',
    }
    
    let [payment_item_list,setPaymentItemList] = React.useState([paymentItemListInitialState]);
    let [payee_payer_list,setPayeePayerList] = React.useState([]);
    let [payment_id,setPaymentId] = React.useState(0);
    let [payment_total_amount,setTotalAmount] = React.useState(0);
    let [payment_date,setPaymentDate] = React.useState(new Date());
    let [month_date,setMonthDate] = React.useState(new Date());
    let [contact_info,setContactInfo] = React.useState([]);
    let [current_sequence_arr,setCurrentSequenceArr] = React.useState([]);
    let [is_show_save_payment,setShowSavePayment] = React.useState(false);
    let [is_show_payment_list,setShowPaymentList] = React.useState(false);
    let [payment_type,setPaymentType] = React.useState('');
    let [payer_type,setPayerType] = React.useState('Payer');
    let [current_sequence,setCurrentSequence] = React.useState('');
    let [isSaveClicked,setIsSaveClicked] = React.useState(false);
    let [selected_class,setClass] = React.useState(0);
    let [selected_section,setSection] = React.useState(0);
    let [paymentData,setPaymentData] = React.useState(paymentDataInitialState);
    let [isShowExistingTemplate,setShowTemplate] = React.useState(false);
    let [existingTemplate,setExistingTemplate] = React.useState([]);
    
    const state = useSelector((state) => state);
    const payment_modes = state.payment_modes;
    const payment_info = state.payment_info;
    const payment_type_list = state.payment_type_list;
    const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            if(props.payment_data){
                let payment_data = props.payment_data;
                if(payment_data.is_db===undefined){
                    if(props.is_demand_bill===true){
                      payment_data.payment_type = 'payment_in';
                    }
                    handleLoadDataOnFly(payment_data);
                }else{
                  let is_demand_bill = false;
                  if(props.is_demand_bill===true){
                    is_demand_bill = true;
                  }
                    APP_HTTP.REQUEST('payment/get_payment_on_id',{id:payment_data.payment_id,is_demand_bill:is_demand_bill},true,true).then((response) => {
                        let resp = response.data.response;
                        if(is_demand_bill===true){
                          resp.payment_type = 'payment_in';
                        }
                        handleLoadDataOnFly(resp);
                        dispatch(handleSetPaymentInfo(resp));
                    });
                }
            }else{
                if(props.is_convert_to_payment!==undefined && props.is_convert_to_payment===true){
                  let is_demand_bill = true;
                  let pid = props.id;
                  
                  APP_HTTP.REQUEST('payment/get_payment_on_id',{id:pid,is_demand_bill:is_demand_bill},true,true).then((response) => {
                      let resp = response.data.response;
                      resp.payment_type = 'payment_in';
                      handleLoadDataOnFly(resp);
                      dispatch(handleSetPaymentInfo(resp));
                      let pay_sequence = {};
                      APP_HTTP.REQUEST('settings/get_current_sequence',{type:'payment_in'},true,true).then((response) => {
                          let resp = response.data;
                          let sequence = resp.response;
                          pay_sequence['payment_in'] = sequence;
                          setCurrentSequence(sequence);
                      });
                  });
                }else{
                  let seq_type = 'payment_in';
                  if(props.is_demand_bill===true){
                    seq_type = 'demand_bill';
                    //setPaymentType(seq_type);
                  }
                  let pay_sequence = {};
                  APP_HTTP.REQUEST('settings/get_current_sequence',{type:seq_type},true,true).then((response) => {
                      let resp = response.data;
                      let sequence = resp.response;
                      pay_sequence['payment_in'] = sequence;
                      setCurrentSequence(sequence);
                  });
                  APP_HTTP.REQUEST('settings/get_current_sequence',{type:'payment_out'},true,true).then((response) => {
                      let resp = response.data;
                      let sequence = resp.response;
                      pay_sequence['payment_out'] = sequence;
                  });
                  setCurrentSequenceArr(pay_sequence);
                }
            }
        }
       
    },[props])
  
  const handleLoadDataOnFly = (resp)=>{
    setPaymentData(resp);
    setPaymentType(resp.payment_type);
    if(resp.payment_type==='payment_in'){
      setPayerType('Payer');
      APP_HTTP.REQUEST('student/search_student',{search:resp.contact_info.section_id,type:'autocomplete',class:resp.contact_info.class_id,section:resp.contact_info.section_id},true,true,false,true).then((stresponse) => {
        let stresp = stresponse.data;
        let search_list = stresp.response;
        setPayeePayerList(search_list);
      });
    }else if(resp.payment_type==='payment_out'){
      setPayerType('Payee');
      APP_HTTP.REQUEST('staff/search_staff',{search:'',type:'autocomplete'},true,true,false,true).then((stresponse) => {
        let stresp = stresponse.data;
        let search_list = stresp.response;
        setPayeePayerList(search_list);
      });
    }
    if(resp.contact_info && resp.contact_info.class_id){
      setClass(resp.contact_info.class_id)
    }
    if(resp.contact_info && resp.contact_info.section_id){
      setSection(resp.contact_info.section_id);
    }
    if(resp.contact_info && resp.contact_info.autocomplete){
      setContactInfo(resp.contact_info.autocomplete);
    }
    if(resp.payment_date){
      setPaymentDate(new Date(resp.payment_date));
    }
    if(resp.for_month){
      setMonthDate(new Date(resp.for_month));
    }
    setCurrentSequence(resp.payment_sequence);
    setPaymentId(resp.update_id);
    if(resp.list_item){
      setShowPaymentList(true);
      setShowSavePayment(true);
      setPaymentItemList(resp.list_item);
      handleSetTotalAmount(resp.list_item);
    }
  }

  const handleSaveInformation = (type) =>{
    setIsSaveClicked(true);
    let sendData = {
      update_id : payment_id,
      payment_sequence : current_sequence,
      payment_type : payment_type,
      payment_date : payment_date,
      month_date : month_date,
      contact_info : contact_info,
      payment_total_amount : payment_total_amount,
      payment_item_list : payment_item_list,
      is_demand_bill : (props.is_demand_bill===true)?true:false,
      is_convert_to_payment : (props.is_convert_to_payment===true)?props.id:0,
    }
    APP_HTTP.REQUEST('payment/save_payment',sendData,true,true).then((response) => {
        let resp = response.data;
        let id = resp.id;
        setIsSaveClicked(false);
        if(props.is_demand_bill===true){
          window.location.href = '/payment/view/demand-bill/'+id;
         // dispatch(handleChangeMenu('payment','view/demand-bill/'+id,'View Demand Bill',false));
        }else{
          dispatch(handleChangeMenu('payment','view/'+id,'View Payment',false));
        }
        dispatch(handleToggleViewPayment(true));
    });
  }
  const handleChangeData = (index,id,value) =>{
    let pilist = [...payment_item_list];
    pilist[index][id] = value;
    handleSetPaymentList(pilist);
  }
  const handleCancelEdit = () =>{
    dispatch(handleChangeMenu('payments','','Payments',true));
    dispatch(handleToggleEditPayment(false));
  }
  
  const handleChangePaymentType = (event) => {
    let thisvalue = event.target.value;
    if(thisvalue==='payment_in' || thisvalue==='demand_bill'){
      setPayerType('Payer');
    }else if(thisvalue==='payment_out'){
      setPayerType('Payee');
      APP_HTTP.REQUEST('staff/search_staff',{search:'',type:'autocomplete'},true,true,false,true).then((response) => {
        let resp = response.data;
        let search_list = resp.response;
        setPayeePayerList(search_list);
      });
    }
    APP_HTTP.REQUEST('settings/get_option',{name:thisvalue},true,true).then((response) => {
        let resp = response.data.response;
        if(resp && resp.value && resp.value.length>0){
          setShowTemplate(true);
          setExistingTemplate(resp.value);
        }
    });
    if(thisvalue==='demand_bill'){
      thisvalue = 'payment_in';
    }
    setPaymentType(thisvalue);
    let sequence = current_sequence_arr[thisvalue];
    setCurrentSequence(sequence)
  };
  const handleChangeInputValue = (value) =>{
    
  }
  const handleChangeValue = (value) =>{
    setShowPaymentList(true);
    setShowSavePayment(true);
    setContactInfo(value)
  }
  const handleChangeSection = (id,value) =>{
    setSection(value);
    APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',class:selected_class,section:value},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setPayeePayerList(search_list);
    });
  }
  const handleChangeClass = (id,value) =>{
    setClass(value);
    setSection(0);
    APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',class:value},true,true,false,true).then((response) => {
      let resp = response.data;
      let search_list = resp.response;
      setPayeePayerList(search_list);
    });
  }
  const handleSetPaymentList = (pilist) =>{
    setPaymentItemList(pilist);
    handleSetTotalAmount(pilist);
    
  }
  const handleSetTotalAmount = (pilist)=>{
    let total_amount = 0;
    for (let index = 0; index < pilist.length; index++) {
      const element = pilist[index];
      let amount = element.amount;
      if(amount!==""){
        amount = parseFloat(amount);
        total_amount = total_amount+amount;
      }
    }
    setTotalAmount(total_amount);
    let pinfo = payment_info;
    pinfo.paid_amount = total_amount;
    dispatch(handleSetPaymentInfo(pinfo));
  }
  const addMorePaymentItem = () =>{
    let pilist = [...payment_item_list];
    pilist.push(paymentItemListInitialState);
    handleSetPaymentList(pilist);
  }
  const handleRemoveItem = (index) =>{
    let plist = [...payment_item_list];
    if(index>0){
      plist.splice(index,1);
      handleSetPaymentList(plist);
    }else{
      handleSetPaymentList([paymentItemListInitialState]);
    }
  }
   
  const handleChangePaymentMode = (id,value) =>{
    let pilist = [...payment_item_list];
    pilist[id]['payment_mode'] = value;
    handleSetPaymentList(pilist);
  }
  const handleChangeParticulars = (id,value) =>{
    let pilist = [...payment_item_list];
    let fee = '';
    let type_name = '';
    for (let index = 0; index < payment_type_list.length; index++) {
      const element = payment_type_list[index];
      if(parseInt(element.id)===parseInt(value)){
        let type_name = element.name;
        let global_fee = element.global_fee;
        let class_fee = element.class_fee;
        if(global_fee!=="" && global_fee!==null){
          fee = global_fee;
          break;
        }else{
          if(selected_class>0){
            
            if(class_fee!==null && class_fee[selected_class]!==undefined && class_fee[selected_class]!==""){
              fee = class_fee[selected_class];
              break;
            }
          }
        }
      }
    }
    if((parseInt(value)===3) && contact_info.id>0){
      if(contact_info.opening_balance!==undefined && parseFloat(contact_info.opening_balance)>0){
        fee = contact_info.opening_balance; 
      }
      /* APP_HTTP.REQUEST('student/get_student_opening_balance',{student_id:contact_info.id},true,true).then((response) => {
        let ob = response.data.response;
        fee = ob; 
      }); */
    }
    pilist[id]['particulars'] = value;
    pilist[id]['particulars_name'] = type_name;
    pilist[id]['amount'] = fee;
    handleSetPaymentList(pilist);
  }
  const handleChangeDate = (id,value) =>{
    setPaymentDate(value);
  }
  const handleChangeMonthDate = (id,value) =>{
    setMonthDate(value);
  }
  const handleShowExisitingTemplate = () =>{
    setPaymentItemList(existingTemplate);
    handleSetTotalAmount(existingTemplate);
  }
  return (
    <div id="create-payment">
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
          <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2} className="mrtop-10"> 

                  <Grid item xs={12} sm={12} md={2}>
                    <Typography variant='span' sx={{fontSize:'30px',fontWeight:'bold',color:'gray'}}>{(current_sequence!=="")?current_sequence:''}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} sx={{mt:'8px'}}>
                    
                  <FormControl>
                      <RadioGroup
                          row
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="row-radio-buttons-group"
                          value={payment_type}
                          onChange={handleChangePaymentType}
                      >
                        {(props.is_demand_bill===true) &&
                        <FormControlLabel value="payment_in" control={<Radio sx={{paddingTop:'2px',paddingBottom:'2px',color: 'green',
'&.Mui-checked': {
color: 'green',
},}}/>} label="DEMAND BILL" />
}
                      {(props.is_demand_bill!==true) &&
                      <>
                          <FormControlLabel value="payment_in" control={<Radio sx={{paddingTop:'2px',paddingBottom:'2px',color: 'green',
'&.Mui-checked': {
color: 'green',
},}}/>} label="RECEIVE PAYMENT" />
{(props.is_convert_to_payment!==true) &&
                          <FormControlLabel value="payment_out" control={<Radio sx={{paddingTop:'2px',paddingBottom:'2px',color: 'red',
'&.Mui-checked': {
color: 'red',
},}}/>} label="SEND PAYMENT" />
}
</>
}
                      </RadioGroup>
                  </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={1}></Grid>
                  {(payment_type!=='') &&
                  <>
                  <Grid item xs={12} sm={12} md={2}> 
                      <BasicDatePicker label="Payment Date" size="small" id="payment_date" handleChangeData={handleChangeDate} defaultValue={payment_date}/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}> 
                      <BasicDatePicker label="For Month" size="small" id="month_date" handleChangeData={handleChangeMonthDate} defaultValue={month_date} format='MMM YYYY' views={['year', 'month']}/>
                  </Grid>
                  </>
                  }
                  <Grid item xs={12} sm={12} md={3}></Grid>
              </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}><Divider/></Grid>
          {(payment_type!=='') &&
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2} className="mrtop-10"> 
              {(payment_type==='payment_in') &&
              <>
               <Grid item xs={12} sm={12} md={3}>
                <Classes show="dropdown" handleChangeData={handleChangeClass} defaultValue={selected_class}/>
              </Grid>
              {(selected_class>0) &&
                <Grid item xs={12} sm={12} md={3}>
                  <Sections show="dropdown" handleChangeData={handleChangeSection}  defaultValue={selected_section}/>
                </Grid>
              }
              </>
              }
              {(selected_class>0 || payment_type==='payment_out') &&
              <>
              <Grid item xs={12} sm={12} md={3}>
                  <Autocomplete
                      value={contact_info}
                      noOptionsText={'No '+payer_type+' Found ! Type To Search'}
                      onChange={(event, newValue) => handleChangeValue(newValue)}
                      onInputChange={(event, newInputValue) => handleChangeInputValue(newInputValue)}
                      disablePortal
                      id="payee_dropdown"
                      options={payee_payer_list}
                      size="small"
                      renderInput={(params) => <TextField {...params} label={'Select '+payer_type} />}
                  />
              </Grid>
              <Grid item xs={12} sm={12} md={3} sx={{textAlign:'center'}}>
                {(contact_info.opening_balance!==undefined && parseFloat(contact_info.opening_balance)>0) &&
                <Typography sx={{background: '#e31119',color: '#fff',padding: '7px'}}>OLD BALANCE : ₹ {AppUtils.currency_format(contact_info.opening_balance)}</Typography>
                }
              </Grid>
              </>
              }
              <Grid item xs={12} sm={12} md={12}><Divider/></Grid>
            </Grid>
          </Grid>
          }
        </Grid>
        {(is_show_payment_list===true) &&
          <Grid container spacing={2} className="mrtop-30">
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>

                  {(isShowExistingTemplate===true) &&
                  <Grid item xs={12} sm={12} md={12} sx={{textAlign:'right'}}>
                      <Button variant='outlined' size='small' onClick={handleShowExisitingTemplate}>Use Existing Template</Button>
                  </Grid>
                  }
                  <Grid item xs={12} sm={12} md={12}>
                    <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>SNO</StyledTableCell>
                          <StyledTableCell>PAYMENT MODE</StyledTableCell>
                          <StyledTableCell>PARTICULARS</StyledTableCell>
                          <StyledTableCell>REFERENCE</StyledTableCell>
                          <StyledTableCell>AMOUNT</StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {payment_item_list.map((value,index)=>{
                            return (
                              <StyledTableRow>
                                <StyledTableCell>
                                  <Typography>{index+1}</Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography>
                                    {(props.is_demand_bill!==true) &&
                                    <AppDropDown label="Payment Mode" list_item={payment_modes} size="small" handleChangeData={handleChangePaymentMode} defaultValue={value.payment_mode} id={index}/>
                                    }
                                    {(props.is_demand_bill===true) &&
                                    <AppDropDown label="Payment Mode" list_item={[{key:'5',value:'Credit'}]} size="small" handleChangeData={handleChangePaymentMode} defaultValue={value.payment_mode} id={index}/>
                                    }
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <PaymentTypes show="dropdown" defaultValue={value.particulars} handleChangeData={handleChangeParticulars} id={index}/>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <TextField
                                    margin="dense"
                                    label="Reference"
                                    type="text"
                                    fullWidth
                                    size="small"
                                    value={value.reference}
                                    onChange={(event)=>handleChangeData(index,'reference',event.target.value)}
                                  />
                                </StyledTableCell>
                                <StyledTableCell>
                                  <TextField
                                    margin="dense"
                                    label="Enter Amount"
                                    type="text"
                                    fullWidth
                                    size="small"
                                    value={value.amount}
                                    onChange={(event)=>handleChangeData(index,'amount',event.target.value)}
                                  />
                                </StyledTableCell>
                                <StyledTableCell sx={{textAlign:'right'}}>
                                  <Close fontSize='small' sx={{cursor:'pointer'}} onClick={() => handleRemoveItem(index)}/>
                                </StyledTableCell>
                                <StyledTableCell sx={{textAlign:'right'}}>
                                  {((payment_item_list.length - 1)==index) &&
                                    <Button onClick={addMorePaymentItem} size='small' sx={{fontSize:'9px'}}>Add More</Button>
                                  }
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                        })}
                        <StyledTableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell><Typography variant='h5'>₹ {AppUtils.currency_format(payment_total_amount)}</Typography></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}><Divider/></Grid>
          </Grid>
        }
        {(is_show_save_payment===true) &&
          <Grid container spacing={2} className="mrtop-30">
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                  <Grid  item xs={12} sm={12} md={6}>
                      {(props.payment_data) &&
                      <Button variant="outlined"  sx={{mt:'13px'}} onClick={handleCancelEdit}>Cancel</Button>
                      }
                  </Grid>
                  <Grid  item xs={12} sm={12} md={6} sx={{textAlign:'right'}}>
                  {(isSaveClicked===false) &&
                      <Button variant="contained" sx={{mt:'13px'}} onClick={() => handleSaveInformation('save')}>Save Information</Button>
                  }
                  {(isSaveClicked===true) &&
                      <LoaderButton label="Save Information"/>
                  }
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}><Divider/></Grid>
          </Grid>
        }
        </Box>
    </div>
  );
 
}
export default CreatePayment;