import { Box, Button, ButtonGroup, Dialog, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import BasicDatePicker from '../DatePicker';
import { Check, Close, Edit, Print, PrintSharp } from '@mui/icons-material';
import ExamTypes from '../settings/exam/ExamTypes';
import Classes from '../settings/classes/Classes';
import Subjects from '../settings/subjects/Subjects';
import DeleteConfirm from '../DeleteConfirm';
import { handleConfirmDeleteScheduledExam } from './Action';
import PrintScheduledExam from './PrintScheduledExam';
import PrintHallTicket from './PrintHallTicket';
import AppUtils from '../../AppUtils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
     
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
export default function HallTicketGeneration(props) {
    const state = useSelector((state) => state);
    const class_list = state.class_list;
    const [is_exam_type_selected,setIsExamType] = React.useState(false);
    const [exam_type,setExamType] = React.useState(0);
    const [exam_type_name,setExamTypeName] = React.useState('');
    const [is_class_selected,setIsClass] = React.useState(false);
    const [className,setClass] = React.useState(0);
    const [is_show_skelton,setShowSkelton] = React.useState(false);
    const [is_print,setIsPrint] = React.useState(false);
    const [print_data,setPrintData] = React.useState([]);
    const [routineData,setRoutineData] = React.useState([]);
    const [studentInfo,setStudentInfo] = React.useState([]);
    const [schoolInfo,setSchoolInfo] = React.useState([]);
    const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
        }
    },[])
    const handleChangeExamType = (id,value) =>{
        setExamType(value);
        if(value>0){
            setIsExamType(true);
        }else{
            setIsExamType(false);
        }
    }
   
   const handleSelectClass = (id,value) =>{
        setClass(value);
        if(value>0){
            setIsClass(true);
        }else{
            setIsClass(false);
        }
   }
   const handleGenerateHallTicket = () =>{
    setShowSkelton(true);
    let send_data = {
        exam_type : exam_type,
        class_id : className,
      };
      APP_HTTP.REQUEST('exam/get_scheduled_exam_on_type_class',send_data,true,true).then((response) => {
          let resp = response.data.all.sorted_schedules;
          let school_info = response.data.all.school_info;
          let ex_type_name = response.data.all.exam_type_name;
          setExamTypeName(ex_type_name);
          setRoutineData(resp);
          setSchoolInfo(school_info);
      });
    APP_HTTP.REQUEST('student/get_students_on_params',{exam_type:exam_type,class:className},true,true).then((response) => {
        let resp = response.data.response;
        setStudentInfo(resp);
        setShowSkelton(false);
      });
   }
   const handlePrintAll = () =>{
        let pdata = {
            exam_type_name : exam_type_name,
            school_info : schoolInfo,
            studentData : studentInfo,
            routineData : routineData,
        }
        setPrintData(pdata);
        setIsPrint(true);
        setTimeout(() => {
            setIsPrint(false);
        }, 1000);
   }
   const handlePrintIndividual = (index) =>{
        let stinfo = [...studentInfo];
        let pdata = {
            exam_type_name : exam_type_name,
            school_info : schoolInfo,
            studentData : [stinfo[index]],
            routineData : routineData,
        }
        setPrintData(pdata);
        setIsPrint(true);
        setTimeout(() => {
            setIsPrint(false);
        }, 1000);
   }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <h2 style={{margin:'0px'}}>HALL TICKET GENERATION</h2>
                    <p style={{margin:'0px',fontSize:'14px'}}>Generate & Print student hall ticket by class or individual student by applying following filter.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={3} spacing={2}>
                    <ExamTypes show="dropdown" handleChangeData={handleChangeExamType} defaultValue={exam_type}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3} spacing={2}>
                        {(is_exam_type_selected) &&
                            <Classes show="dropdown" className="student-data" handleChangeData={handleSelectClass} defaultValue={className}/>
                        }
                </Grid>
                {(AppUtils.has_access(state,'9_hall_ticket_generation_view') || AppUtils.has_access(state,'9_hall_ticket_generation_add') || AppUtils.has_access(state,'9_hall_ticket_generation_edit')) &&
                <Grid item xs={12} sm={12} md={3} spacing={2}>
                    {(is_class_selected) &&
                        <Button onClick={handleGenerateHallTicket} variant='outlined' size='medium'>Generate Hall Ticket</Button>
                    }
                </Grid>
                }
                {(AppUtils.has_access(state,'9_hall_ticket_generation_print')) &&
                <Grid item xs={12} sm={12} md={3} spacing={2} sx={{textAlign:'right'}}>
                    {(studentInfo.length>0) &&
                        <Button onClick={handlePrintAll} variant='outlined' size='medium'><PrintSharp />  Print All</Button>
                    }
                </Grid>
                }
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                {(is_show_skelton===true) &&
                  <>
                   <Grid item xs={12} sm={12} md={3} spacing={2}>
                        <Stack spacing={1}>
                            <Skeleton variant="rectangular" sx={{width:'100%'}} height={250} />
                        </Stack>
                    </Grid>
                   <Grid item xs={12} sm={12} md={3} spacing={2}>
                        <Stack spacing={1}>
                            <Skeleton variant="rectangular" sx={{width:'100%'}} height={250} />
                        </Stack>
                    </Grid>
                   <Grid item xs={12} sm={12} md={3} spacing={2}>
                        <Stack spacing={1}>
                            <Skeleton variant="rectangular" sx={{width:'100%'}} height={250} />
                        </Stack>
                    </Grid>
                   <Grid item xs={12} sm={12} md={3} spacing={2}>
                        <Stack spacing={1}>
                            <Skeleton variant="rectangular" sx={{width:'100%'}} height={250} />
                        </Stack>
                    </Grid>
                  </>
                }
                {(is_show_skelton===false) &&
                    <>
                        {(studentInfo.map((value,index)=>{
                            return (
                                <Grid item xs={12} sm={12} md={3} spacing={2}>
                                   <Card sx={{ maxWidth: 345 }}>
                                        <CardHeader
                                            avatar={
                                                <Avatar alt={value.student_name} src={value.photo} />
                                            }
                                            title={value.student_name}
                                            subheader={value.roll_number+' '+value.class+'('+value.section+')'}
                                        />
                                        <Divider />
                                        <CardContent>
                                        <table border={1} cellSpacing={0} cellPadding={3} style={{fontSize:'12px',textAlign:'left',borderCollapse: 'collapse',border:'1px solid #d7d7d7'}} width={'100%'} >
                                            <tbody>
                                                <tr>
                                                    <th colSpan={3}>Exam Time Table</th>
                                                </tr>            
                                                <tr>
                                                    <th style={{textAlign:'left'}}>Subject</th>
                                                    <th style={{textAlign:'left'}}>Date & Time</th>
                                                </tr>            
                                                {routineData && routineData.map((value,index)=>{
                                                    return (
                                                        <>
                                                        {(value.active===true) &&
                                                        <tr>
                                                            
                                                            <td style={{textAlign:'left'}}>
                                                            <span>
                                                                {value.name}
                                                            </span>
                                                            </td>
                                                            <td style={{textAlign:'left'}}>
                                                            {value.exam_date_display}
                                                            </td>
                                                        </tr>
                                                    }
                                                        </>
                                                    )
                                                })}      
                                            </tbody>
                                        </table>
                                        </CardContent>
                                        <Divider />
                                        {(AppUtils.has_access(state,'9_hall_ticket_generation_print')) &&
                                        <CardActions disableSpacing>
                                            <IconButton aria-label="Print Hall Ticekt">
                                                <PrintSharp onClick={()=>handlePrintIndividual(index)}/>
                                            </IconButton>
                                        </CardActions>
                                        }
                                    </Card>
                                </Grid>
                            )
                        }))}
                    </>
                }
            </Grid>
        </Box>
        {(is_print===true) &&
            <PrintHallTicket data={print_data}/>
        }
    </Box>
  );
}