import { Button } from '@mui/material';
import React from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
const DownloadAttendence = (props) =>  {
        const tableRef = React.useRef(null);
        return (
            <>
            <DownloadTableExcel
                filename="Attendece Report"
                sheet="attendece"
                currentTableRef={tableRef.current}
            >
            <Button variant='outlined' size='medium'>DOWNLOAD</Button>
            </DownloadTableExcel>
            <div style={{display:'none'}}>
                <table ref={tableRef}>
                    <tbody>
                        <tr>
                            <th colSpan={2} style={{textAlign:'left',background:'#1976d2',color:'#fff', fontSize:'24px'}}><span style={{textTransform:'capitalize'}}>{(props.data.attendence_type==='staff')?'STAFF':'STUDENT'} ATTENDENCE REPORT</span></th>
                        </tr>
                    {props.data.attendence_ids.map((value,index)=>{
                      return (
                        <>
                            <tr>
                                <th colSpan={2} style={{textAlign:'left',background:'#19d29d',color:'#fff', fontSize:'20px'}}>
                                <span>
                                {props.data.attendence_info[value].info_sequence} | {props.data.attendence_info[value].info_name}
                                {(props.data.attendence_info[value].roll_number) &&  
                                <>
                                - {props.data.attendence_info[value].roll_number}/{props.data.attendence_info[value].class}({props.data.attendence_info[value].section})
                                </> 
                                }
                                </span>
                                </th>
                            </tr>
                            {(props.data.attendence_list[value]!==undefined) &&
                                <>
                                {props.data.attendence_list[value].map((attval,idx)=>{
                                    return(
                                        <tr>
                                            <td  style={{textAlign:'left'}}>
                                                {attval.date}
                                            </td>
                                            <td style={{textAlign:'left',background:(attval.present===1)?'#19d29d':(attval.present===0)?'red':'#d1d1d1',color:'#fff'}}>
                                            {(attval.present===1) &&
                                                <>
                                                  <span>
                                                    Present
                                                  </span>
                                                </>
                                              }
                                              {(attval.present===0) &&
                                                <>
                                                  
                                                  <span>
                                                    Absent
                                                  </span>
                                                </>
                                              }
                                              {(attval.present===2) &&
                                                <>
                                                    
                                                    <span style={{color:'gray'}}>
                                                      Leave
                                                    </span>
                                                </>
                                              }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </>
                            }
                        </>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            </>
        );
}
export default DownloadAttendence