import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import APP_HTTP from '../../APP_HTTP';

import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

let paymentDataInitialState = {
    
}

const PrintPayment = (props)=> {
    let [current_sequence,setCurrentSequence] = React.useState('');
    let [paymentData,setPaymentData] = React.useState(paymentDataInitialState);
    
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            
            initialized.current = true;
            
            if(props.payment_data){
                let payment_data = props.payment_data;
                if(payment_data.is_db===undefined){
                    setPaymentData(payment_data);
                    setCurrentSequence(payment_data.payment_sequence);
                    setTimeout(() => {
                        handlePrintPayment();
                    }, 100);
                    
                }else{
                    let is_demand_bill = false;
                    if(props.is_demand_bill!==undefined){
                        is_demand_bill = props.is_demand_bill;
                    }
                    APP_HTTP.REQUEST('payment/get_payment_on_id',{id:payment_data.payment_id,is_demand_bill:is_demand_bill},true,true).then((response) => {
                        let resp = response.data.response;
                        setPaymentData(resp);
                        setCurrentSequence(resp.payment_sequence);
                        setTimeout(() => {
                        
                        handlePrintPayment();
                    }, 100);
                        //dispatch(handleSetPaymentInfo(resp));
                    });
                }
            }
            
        }
       
    },[props])
   const handlePrintPayment = ()=>{
    if(document.getElementById("paymentPrintBlock")){
        var divContents = document.getElementById("paymentPrintBlock").innerHTML; 

      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
     
          a.document.write('<style type="text/css"><style type="text/css">@page{size:"A4";margin:5cm}table{font-size:14px;font-weight:bold;width:100%;margin-bottom:1rem;color:#212529;border-collapse:collapse}table td,table th{padding:.75rem;vertical-align:top;border:1px solid #dee2e6}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
           a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
           a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');  
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="paymentPrintBlock" style={{display:'none'}}>
        <table>
            <PrintHeader header_data={paymentData.school_info}/>
            {(props.is_demand_bill!==undefined && props.is_demand_bill===true) &&
                <tr>
                    <th colSpan={5} style={{textAlign:'center'}}>DEMAND BILL</th>
                </tr>
            }
            <tr>
                <th style={{textAlign:'left',borderRight:'none'}} colSpan={3}>
                <h4 style={{fontSize:'20px',fontWeight:'bold'}}>
                    {(paymentData.contact_info && paymentData.contact_info.student_sequence) &&
                        <span>{paymentData.contact_info.student_sequence} / </span>
                    }
                    {(paymentData.contact_info && paymentData.contact_info.staff_sequence) &&
                        <span>{paymentData.contact_info.staff_sequence} / </span>
                    }
                    <span> {paymentData.contact_name}</span>
                    </h4>
                    {(paymentData.contact_info) &&
                    <>
                        {(paymentData.contact_info.roll_number) &&
                            <h4 style={{fontSize:'15px',textTransform:'uppercase'}}>
                                {paymentData.contact_info.roll_number} | {paymentData.contact_info.class}({paymentData.contact_info.section})
                            </h4>
                        }
                        
                        <h4 style={{fontSize:'15px'}}>
                            {(paymentData.contact_info.mobile_number!=="") &&
                                <span>{paymentData.contact_info.mobile_number}</span>
                            } 
                            {(paymentData.contact_info.mobile_number!=="" && paymentData.contact_info.alt_mobile_number!=="") &&
                                <span> | </span>
                            } 
                            {(paymentData.contact_info.alt_mobile_number!=="") &&
                                <span>{paymentData.contact_info.alt_mobile_number}</span>
                            } 
                        </h4>
                        <h4 style={{fontSize:'15px'}}>
                            {(paymentData.contact_info.locality!=="") &&
                                <span>{paymentData.contact_info.locality}</span>
                            } 
                            {(paymentData.contact_info.city!=="" && paymentData.contact_info.locality!=="") &&
                                <span>, </span>
                            } 
                            {(paymentData.contact_info.city!=="") &&
                                <span>{paymentData.contact_info.city}</span>
                            } 
                            {(paymentData.contact_info.city!=="" && paymentData.contact_info.state!=="") &&
                                <span>, </span>
                            } 
                            {(paymentData.contact_info.state!=="") &&
                                <span>{paymentData.contact_info.state}</span>
                            } 
                            {(paymentData.contact_info.state!=="" && paymentData.contact_info.pincode!=="") &&
                                <span>, </span>
                            } 
                                {(paymentData.contact_info.pincode!=="") &&
                                <span>{paymentData.contact_info.pincode}</span>
                            } 
                        </h4>
                    </>
                    }
                </th>
                <th colSpan={2}  style={{textAlign:'right',borderLeft:'none'}}>
                    <h4 style={{fontSize:'20px',fontWeight:'bold'}}>{current_sequence}</h4>
                    <h4 style={{fontSize:'15px'}}>Payment On : {paymentData.payment_date_display}</h4>
                </th>
            </tr>
            <tbody>
                <tr>
                <th style={{textAlign:'left',backgroundColor:'rgb(251 251 251)'}} colSpan={5}>PARTICULARS LIST</th>
                </tr>            
                <tr>
                <th style={{textAlign:'left',width:'90px'}}>SNO</th>
                <th style={{textAlign:'left'}}>PAYMENT MODE</th>
                <th style={{textAlign:'left'}}>PARTICULARS</th>
                <th style={{textAlign:'left'}}>REFERENCE</th>
                <th style={{textAlign:'right'}}>AMOUNT</th>
                </tr>            
                            {paymentData.list_item && paymentData.list_item.map((value,index)=>{
                            return (
                              <tr>
                                <td style={{textAlign:'left'}}>
                                  <span>{index+1}</span>
                                </td>
                                <td style={{textAlign:'left'}}>
                                  <span>
                                    {value.payment_mode_txt}
                                  </span>
                                </td>
                                <td style={{textAlign:'left'}}>
                                  {value.particulars_name}
                                </td>
                                <td style={{textAlign:'left'}}>
                                    {value.reference}
                                </td>
                                <td style={{textAlign:'right'}}>₹ {AppUtils.currency_format(value.amount)}</td>
                              </tr>
                            )
                        })}
                            
                            
                        
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={5} style={{textAlign:'right'}}>
                    {(paymentData.paid_amount) &&
                        <>
                        <h4 style={{fontWeight:'bold',fontSize:'25px'}}>₹{AppUtils.currency_format(paymentData.paid_amount)}/-</h4>
                        <p style={{fontWeight:'bold',fontSize:'15px'}}>{AppUtils.number_to_words(paymentData.paid_amount)} Only/-</p>
                        </>
                    }
                    </td>
                </tr>
                <PrintFooter colSpan={5}/>
            </tfoot>
        </table>
    </div>
  );
}
export default PrintPayment;