import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import APP_HTTP from '../../APP_HTTP';

import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

let staffDataInitialState = {
    
}

const PrintStaff = (props)=> {
    let [current_sequence,setCurrentSequence] = React.useState('');
    let [staffData,setStaffData] = React.useState(staffDataInitialState);
    
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            
            initialized.current = true;
            
            if(props.staff_data){
                let staff_data = props.staff_data;
                if(staff_data.is_db===undefined){
                    setStaffData(staff_data);
                    setCurrentSequence(staff_data.staff_sequence);
                    setTimeout(() => {
                        handlePrintStaff();
                    }, 100);
                    
                }else{
                    APP_HTTP.REQUEST('staff/get_staff_on_id',{id:staff_data.staff_id},true,true).then((response) => {
                        let resp = response.data.response;
                        setStaffData(resp);
                        setCurrentSequence(resp.staff_sequence);
                        setTimeout(() => {
                        
                        handlePrintStaff();
                    }, 100);
                        //dispatch(handleSetStaffInfo(resp));
                    });
                }
            }
            
        }
       
    },[props])
   const handlePrintStaff = ()=>{
    if(document.getElementById("staffPrintBlock")){
        var divContents = document.getElementById("staffPrintBlock").innerHTML; 

      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
     
          a.document.write('<style type="text/css"><style type="text/css">@page{size:"A4";margin:5cm}table{font-size:14px;font-weight:bold;width:100%;margin-bottom:1rem;color:#212529;border-collapse:collapse}table td,table th{padding:.75rem;vertical-align:top;border:1px solid #dee2e6}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
           a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
            a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');  
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="staffPrintBlock" style={{display:'none'}}>
        <table>
            <PrintHeader header_data={staffData.school_info}/>
            <tr>
                <th style={{borderRight:'none',width:'90px',textAlign:'left'}}>
                    <img src={staffData.photo} alt="image" height={'90'}/>
                </th>
                <th style={{textAlign:'left',borderLeft:'none'}} colSpan={4}>
                    <h4 style={{fontSize:'20px',fontWeight:'bold'}}>
                        <span> {staffData.staff_name}</span>
                    </h4>
                    {(staffData) &&
                    <>
                        <h4 style={{fontSize:'15px'}}>
                            {(staffData.mobile_number!=="") &&
                                <span>{staffData.mobile_number}</span>
                            } 
                            {(staffData.mobile_number!=="" && staffData.alt_mobile_number!=="") &&
                                <span> | </span>
                            } 
                            {(staffData.alt_mobile_number!=="") &&
                                <span>{staffData.alt_mobile_number}</span>
                            } 
                        </h4>
                        <h4 style={{fontSize:'15px'}}>
                            {(staffData.locality!=="") &&
                                <span>{staffData.locality}</span>
                            } 
                            {(staffData.city!=="" && staffData.locality!=="") &&
                                <span>, </span>
                            } 
                            {(staffData.city!=="") &&
                                <span>{staffData.city}</span>
                            } 
                            {(staffData.city!=="" && staffData.state!=="") &&
                                <span>, </span>
                            } 
                            {(staffData.state!=="") &&
                                <span>{staffData.state}</span>
                            } 
                            {(staffData.state!=="" && staffData.pincode!=="") &&
                                <span>, </span>
                            } 
                                {(staffData.pincode!=="") &&
                                <span>{staffData.pincode}</span>
                            } 
                        </h4>
                    </>
                    }
                </th>
                
            </tr>
            <tbody>
                <tr>
                    <th style={{textAlign:'left',backgroundColor:'rgb(251 251 251)'}} colSpan={5}>APPOINTMENT INFORMATION</th>
                </tr>  
                <tr>
                    <td colSpan={2}  style={{borderRight:'none',borderBottom:'none'}}>
                        <p style={{fontSize:'14px'}}>Appointment No.</p>
                        <p style={{fontSize:'16px'}}>{staffData.staff_sequence}</p>
                    </td>
                    <td colSpan={2} style={{borderLeft:'none',borderRight:'none',borderBottom:'none'}}>
                        <p style={{fontSize:'14px'}}>Appointment Date</p>
                        <p style={{fontSize:'16px'}}>{staffData.joining_date_display}</p>
                    </td>
                    <td style={{borderLeft:'none',borderBottom:'none'}}>
                        <p style={{fontSize:'14px'}}>Appointment Type</p>
                        <p style={{fontSize:'16px'}}>{staffData.staff_type}</p>
                    </td>
                </tr>
                <tr>
                    <td style={{borderTop:'none',borderRight:'none'}}>
                        <p style={{fontSize:'14px'}}>Salary</p>
                        <p style={{fontSize:'16px'}}>{staffData.salary}/{staffData.salary_type}</p>
                    </td>
                    <td colSpan={4} style={{borderTop:'none',borderLeft:'none'}}>
                        <p style={{fontSize:'14px'}}>Appointment Specialization</p>
                        <p style={{fontSize:'16px'}}>{staffData.specialization_view}</p>
                    </td>
                </tr>
                <tr>
                    <th style={{textAlign:'left',backgroundColor:'rgb(251 251 251)'}} colSpan={5}>ATTACHED FOLLOWING DOCUMENT</th>
                </tr>
                <tr>
                    <td colSpan={5} style={{borderBottom:'none'}}>
                        <input type='checkbox' defaultChecked={(staffData.dobc && staffData.dobc!=='')?true:false}/> Xerox copy of Date Of Birth Certificate
                    </td>
                </tr>
                <tr>
                    <td colSpan={5} style={{borderBottom:'none',borderTop:'none'}}>
                        <input type='checkbox'  defaultChecked={(staffData.photo && staffData.photo!=='')?true:false}/> Photograph of Staff
                    </td>
                </tr>
                <tr>
                    <td colSpan={5} style={{borderBottom:'none',borderTop:'none'}}>
                        <input type='checkbox'  defaultChecked={(staffData.aadharc && staffData.aadharc!=='')?true:false}/> Xerox copy of Aadhar Card
                    </td>
                </tr>
                <tr>
                    <td colSpan={5} style={{borderBottom:'none',borderTop:'none'}}>
                        <input type='checkbox'  defaultChecked={(staffData.experiencec && staffData.experiencec!=='')?true:false}/> Experience Letter
                    </td>
                </tr>
                <tr>
                    <td colSpan={5} style={{borderBottom:'none',borderTop:'none'}}>
                        <input type='checkbox'  defaultChecked={(staffData.payslipc && staffData.payslipc!=='')?true:false}/> Last Pay Slip
                    </td>
                </tr>
              
            </tbody>
            <PrintFooter colSpan={5}/>
        </table>
    </div>
  );
}
export default PrintStaff;