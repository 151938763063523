import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Divider, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AddCircleOutline, ArrowRightAlt, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import { useSelector, useDispatch } from "react-redux";
import { handleConfirmDeleteStaffTypes, handleOpenAddStaffTypes, handleSetStaffTypeList } from './Action.jsx';
import CreateStaffType from './CreateStaffType.jsx';
import DeleteConfirm from '../../DeleteConfirm.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import APP_HTTP from '../../../APP_HTTP.jsx';
import AppUtils from '../../../AppUtils.jsx';

const StaffTypes = (props) => {
    const [is_loading,setLoading] = React.useState(true);
    const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
    const state = useSelector((state) => state);
    const is_open_add_staff_type = state.is_open_add_staff_type;
    const is_delete_item = state.is_delete_item;
    const staff_type_list = state.staff_type_list;
    const initialized = React.useRef(false);
    const dispatch = useDispatch();
    React.useEffect(()=>{
        if(staff_type_list.length===0){
            if (!initialized.current) {
                initialized.current = true;
                APP_HTTP.REQUEST('settings/get_staff_type_list',{},true,true).then((response) => {
                    let resp = response.data;
                    let stf_list = resp.response;
                    setLoading(false);
                    dispatch(handleSetStaffTypeList(stf_list));
                });
            }
        }
    },[dispatch])
    const list_item = [{key:0,value:'Select Staff Type'}];
    for (let index = 0; index < staff_type_list.length; index++) {
        const element = staff_type_list[index];
        let staff_type_item = {key:element.id,value:element.name};
        list_item.push(staff_type_item)
    }
    const [staffTypeValue,setStaffTypeEdit] = React.useState({id:0,name:'',is_edit:true});
    
    const handleDeleteItem = (id)=> {
        let staffTypeValue = staff_type_list[id];
        let staffId = staffTypeValue.id;
        
        let delData = {id:staffId,index:id,target:'staff_type'};
        setDeleteData(delData);
        dispatch(handleConfirmDeleteStaffTypes(true));
    }
    const handleEditItem = (id)=>{
        let staffTypeValue = staff_type_list[id];
        if(staffTypeValue.is_edit===undefined){
            staffTypeValue['is_edit'] = true;
        }else {
            staffTypeValue.is_edit = true;
        }
        if(staffTypeValue.update_id===undefined){
            staffTypeValue['update_id'] = id;
        }else{
            staffTypeValue.update_id = id;
        }
        setStaffTypeEdit(staffTypeValue);
        dispatch(handleOpenAddStaffTypes(true));
    }
    const openAddStaff = () =>{
        setStaffTypeEdit({id:0,name:'',is_edit:false});
        dispatch(handleOpenAddStaffTypes(true));
    }
  return (
    <>
    {(props.show==='list') &&
    <>
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Staff Types
                        {(AppUtils.has_access(state,'24_staff_types_add')) &&
                            <AddCircleOutline sx={{float:'right',marginTop:'3px',cursor:'pointer'}} fontSize='large' onClick={openAddStaff}/>
                        }
                    </Typography>
                </Grid>
                {(AppUtils.has_access(state,'24_staff_types_view')) &&
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px'}}>
                <Divider />
                    <List>
                        {(staff_type_list.map((value,index)=>{
                            return (
                                <>
                                <ListItem key={value.name} disablePadding>
                                    <ListItemButton>
                                        <ListItemText primary={value.name} />
                                        {(value.id>6) &&
                                            <>
                                            {(AppUtils.has_access(state,'24_staff_types_delete')) &&
                                                <Delete fontSize='small' onClick={() =>handleDeleteItem(index)}/>
                                            }
                                            {(AppUtils.has_access(state,'24_staff_types_edit')) &&
                                                <Edit  fontSize='small' onClick={() =>handleEditItem(index)}/>
                                            }
                                            </>
                                        }
                                    </ListItemButton>
                                </ListItem>
                                <Divider/>
                            </>
                            )
                        }))}
                        {(is_loading===false && staff_type_list.length===0) &&
                             <ListItem key={"No Record Found"} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"No Record Found"} />
                                </ListItemButton>
                            </ListItem>
                        }
                        {(is_loading===true && staff_type_list.length===0) &&
                             <ListItem key={"Loading..."} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"Loading..."} />
                                </ListItemButton>
                            </ListItem>
                        }
                    </List>
                
                </Grid>
                }
            </Grid>
        </Box>
       {(is_open_add_staff_type) && 
        <CreateStaffType staffTypeValue={staffTypeValue}/>
       }
        {(is_delete_item) &&
        <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" />
       }
    </>
    }
    {(props.show==='dropdown') &&
        <AppDropDown label="Staff Type" id="staff_type_id" defaultValue={(props.defaultValue)?parseFloat(props.defaultValue):0} list_item={list_item} size="small" handleChangeData={props.handleChangeData}/>
    }
    </>
  );
}

export default StaffTypes;