import * as React from 'react';
import { Autocomplete, Avatar, Box, Button, ButtonGroup, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { handleOpenAddPaymentType,handleSavePaymentTypeToList,handleUpdatePaymentType } from './Action';
import APP_HTTP from '../../../APP_HTTP';
import { handleSetClassList } from '../classes/Action';

let initialState = {id:0,name:'',is_edit:false};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const CreatePaymentType = (props)=> {
  const [payment_typeItem,setPaymentTypeItem] = React.useState(initialState);
  const [payment_typeName,setPaymentTypeName] = React.useState('');
  const [global_fee,setGlobalFee] = React.useState('');
  const [isShowClassList,setShowClassList] = React.useState(false);
  const [class_fee,setClassFee] = React.useState({});
  const state = useSelector((state) => state);
  const class_list = state.class_list;
  const is_open_add_payment_type = state.is_open_add_payment_type;
  const dispatch = useDispatch();
  const initialized = React.useRef(false);
  const handleClose = () => {
    initialized.current = false;
    dispatch(handleOpenAddPaymentType(false));
  };
  React.useEffect(() => {
    if (!initialized.current) {
        initialized.current = true;
        let payment_typeValue = props.payment_typeValue;
        let cls_fee = props.class_fee;
        setClassFee(cls_fee);
        setPaymentTypeItem(payment_typeValue);
        setPaymentTypeName(payment_typeValue.name);
        setGlobalFee(payment_typeValue.global_fee);
        if(class_list.length===0){
          APP_HTTP.REQUEST('settings/get_class_list',{},true,true).then((response) => {
              let resp = response.data;
              let class_list = resp.response;
              dispatch(handleSetClassList(class_list));
              setShowClassList(true);
          });
        }else{
          setShowClassList(true);
        }
    }
  },[props]);
  const handleSavePaymentType = () =>{
    let update_idx = 0;
    let update_id = 0;
    if(props.payment_typeValue && props.payment_typeValue.is_edit && props.payment_typeValue.update_id>=0){
      update_idx = props.payment_typeValue.update_id;
      update_id = props.payment_typeValue.id;
      dispatch(handleUpdatePaymentType(update_idx,payment_typeName));
    }
    APP_HTTP.REQUEST('settings/create_payment_type',{id:update_id,name:payment_typeName,class_fee:class_fee,global_fee,global_fee},true,true).then((response) => {
      let resp = response.data;
      let id = resp.id;
      if(update_id===0){
        payment_typeItem.id = parseInt(id);
        dispatch(handleSavePaymentTypeToList(payment_typeItem));
      }
    });
    handleClose();
  }
  const handleSetPaymentTypeName = (e) =>{
    let thisvalue = e.currentTarget.value;
    let clsitem = payment_typeItem;
    clsitem.name = thisvalue;
    setPaymentTypeItem(clsitem);
    setPaymentTypeName(thisvalue);
  }
  const handleSetGlobalFee = (e) =>{
    let thisvalue = e.currentTarget.value;
    let clsitem = payment_typeItem;
    clsitem.global_fee = thisvalue;
    setPaymentTypeItem(clsitem);
    setGlobalFee(thisvalue);
  }
  const handleChangeAmount = (id,value) =>{
      class_fee[id] = value;
      setClassFee(class_fee);
  }
  return (
      <Dialog open={is_open_add_payment_type} onClose={handleClose}>
        <DialogTitle>Create Payment Type</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="payment_type_name"
            label="Enter Payment Type Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleSetPaymentTypeName}
            value={payment_typeName}
          />
          <TextField
            autoFocus
            margin="dense"
            id="global_fee"
            label="Enter Global Fee"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleSetGlobalFee}
            value={global_fee}
          />
          <Typography variant='h6'>Class & Amount Mapping</Typography>

          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>CLASS</StyledTableCell>
                <StyledTableCell>FEE</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(isShowClassList===true) &&
                <>
                  {class_list && class_list.map((row,index) => (
                    
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <TextField size='small' defaultValue={(class_fee[row.id])?class_fee[row.id]:''} onChange={(event)=>handleChangeAmount(row.id,event.target.value)}/>
                        </StyledTableCell>
                      </StyledTableRow>
                    
                  ))}
                </>
              }
            </TableBody>
          </Table>
        </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='small'>Close</Button>
          <Button onClick={handleSavePaymentType} variant='contained' color='success' size='small'>Save</Button>
        </DialogActions>
      </Dialog>
    
  );
}
export default CreatePaymentType;