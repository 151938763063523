import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const PrintMarksheet = (props)=> {
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            console.log(props.data);
            if(props.data){
                let data = props.data;
                
                setTimeout(() => {
                    handleMarksheetPrint();
                }, 200);
            }
        }
       
    },[props])
   const handleMarksheetPrint = ()=>{
    if(document.getElementById("markSheetPrintBlock")){
        var divContents = document.getElementById("markSheetPrintBlock").innerHTML; 

      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
     
          a.document.write('<style type="text/css"><style type="text/css">@page{size:"A4";margin:5cm}table{font-size:14px;font-weight:bold;width:100%;margin-bottom:1rem;color:#212529;border-collapse:collapse}table td,table th{padding:.75rem;vertical-align:top;border:1px solid black}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
           a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
           a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');  
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="markSheetPrintBlock" style={{display:'none'}}>
        {(props.data && props.data.studentData && props.data.studentData.map((value,index)=>{
            let total_full_marks = 0;
            let total_obtained_marks = 0;
            return (
                <>
                {(value.marksheet_status!==1) &&
                    <table style={{pageBreakAfter:'always'}}>
                        <tr>
                            <td colSpan={3} style={{textAlign:'center'}}>
                                <h4 style={{fontSize:'20px',fontWeight:'bold'}}>
                                    REPORT CARD
                                </h4>
                            </td>
                        </tr>
                    <PrintHeader header_data={props.data.school_info} colSpan={3}/>      
                    <tbody>
                    <tr>
                        <th style={{borderRight:'none',width:'90px',textAlign:'left'}}>
                            <img src={value.photo} alt={value.student_name} height={'90'}/>
                        </th>
                        <th style={{textAlign:'left',borderLeft:'none'}} colSpan={4}>
                            <h4 style={{fontSize:'20px',fontWeight:'bold'}}>
                                <span> {value.student_name}</span>
                            </h4>
                            {(value) &&
                            <>
                                <h4 style={{fontSize:'15px'}}>Class : {value.class}{(value.section!=="")?'('+value.section+')':''}</h4>
                                <h4 style={{fontSize:'15px'}}>Roll Number : {value.roll_number}</h4>
                                <h4 style={{fontSize:'15px'}}>Exam Type : {props.data.exam_type_name} ({value.exam_date})</h4>
                            </>
                            }
                        </th>
                    </tr>
                    <tr>
                        <td colSpan={5}>
                        <table width={'100%'} >
                            <tbody>
                                <tr>
                                    <th colSpan={5} style={{textAlign:'left'}}>
                                        <h4 style={{fontSize:'20px',fontWeight:'bold'}}>Marks</h4>
                                    </th>
                                </tr>            
                                <tr>
                                    <th style={{textAlign:'left',fontSize:'18px'}}>Subject</th>
                                    <th style={{textAlign:'left',fontSize:'18px'}}>Full Marks</th>
                                    <th style={{textAlign:'left',fontSize:'18px'}}>Pass Marks</th>
                                    <th style={{textAlign:'left',fontSize:'18px'}}>Marks Obtained</th>
                                    <th style={{textAlign:'left',fontSize:'18px'}}>Grade</th>
                                </tr>            
                                {value.marks && value.marks.map((marks,index)=>{
                                    total_full_marks = total_full_marks + parseFloat(marks.full_marks);
                                    total_obtained_marks = total_obtained_marks + parseFloat(marks.marks_obtained);
                                    return (
                                        <>
                                            <tr>
                                                <td style={{textAlign:'left'}}>
                                                <span>
                                                    {marks.name}
                                                </span>
                                                </td>
                                                <td style={{textAlign:'left'}}>
                                                    {marks.full_marks}
                                                </td>
                                                <td style={{textAlign:'left'}}>
                                                    {marks.pass_marks}
                                                </td>
                                                <td style={{textAlign:'left'}}>
                                                    {marks.marks_obtained}
                                                </td>
                                                <td style={{textAlign:'left'}}>{marks.grade}</td>
                                            </tr>
                                        </>
                                    )
                                })}    
                                <tr>
                                    <th></th>
                                    <th colSpan={2} style={{textAlign:'left'}}><h4 style={{fontSize:'16px',fontWeight:'bold'}}>{total_full_marks}</h4></th>
                                    <th colSpan={2} style={{textAlign:'left'}}><h4 style={{fontSize:'16px',fontWeight:'bold'}}>{total_obtained_marks}</h4></th>
                                </tr>  
                                <tr>
                                    <th colSpan={5} style={{textAlign:'left'}}>
                                        <h4 style={{fontSize:'20px',fontWeight:'bold'}}>Division : {value.division}</h4>
                                        <h4 style={{fontSize:'20px',fontWeight:'bold'}}>Grade : {value.grade}</h4>
                                    </th>
                                </tr>  
                            </tbody>
                        </table>
                        </td>
                    </tr>
                    </tbody>
                    <PrintFooter colSpan={5}/>
                    </table>
                }
                </>
            )
        }))}
       
        
    </div>
  );
}
export default PrintMarksheet;