import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import { Avatar, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputAdornment, InputLabel, Modal, OutlinedInput, Skeleton, Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { handleChangeMenu } from '../../actions/Action';
import { Close, Send } from '@mui/icons-material';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ViewInbox = (props) => {
    const initialized = React.useRef(false);
    const [message,setMessage] = React.useState('');
    const [isLoading,setIsLoading] = React.useState(false);
    const [inbox_list,setInboxList] = React.useState([]);
    const state = useSelector((state) => state);
    
    const handleClose = () => {
        props.handleClose();
    };

    const dispatch = useDispatch();
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handLoadInboxMessage(props.view_id);
        }
    },[])
    const handLoadInboxMessage = (id) =>{
      setIsLoading(true);
      APP_HTTP.REQUEST('inbox/get_message',{id:id},true,true).then((response) => {
          let resp = response.data;
          let inb_list = resp.response;
          setInboxList(inb_list);
          setIsLoading(false);
      });
    }
 const handleSendMessage = () =>{
  let inb_list = [...inbox_list];
  let msg = {id:inbox_list[0].id,reply_id:props.view_id,message:message,status:0,target:inbox_list[0].type,device:'web'};
  inb_list.push(msg);
  setInboxList(inb_list);
  
  APP_HTTP.REQUEST('inbox/send_message',{id:inbox_list[0].id,reply_id:props.view_id,message:message,status:0,target:inbox_list[0].type,device:'web'},true,true).then((response) => {
    setMessage('');
  });
 }
 const handleSetMessage = (value) =>{
  setMessage(value);
 }
 const handleEnterPress = (e) =>{
   if(e.which===13){
    handleSendMessage();
   }
 }
  return (
      <>
            <Dialog open={true} fullWidth={true}
        maxWidth={'md'}>
            <DialogTitle id="customized-dialog-title">
                    <Typography>From : 
                          {(inbox_list.length>0 && inbox_list[0].type==='student') &&
                            <>{inbox_list[0].contact_info.student_name}</>
                          }
                          {(inbox_list.length>0 && inbox_list[0].type==='parent') &&
                            <>{inbox_list[0].contact_info.father_name} ({inbox_list[0].contact_info.student_sequence})</>
                          }
                          {(inbox_list.length>0 && inbox_list[0].type==='staff') &&
                            <>{inbox_list[0].contact_info.staff_name}</>
                          }
                    </Typography>
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <Close />
                    </IconButton>   
                    <DialogContent dividers>
                <Grid container spacing={2} sx={{marginBottom:'10px'}}>
                   
                    {(inbox_list.map((value,index)=>{
                        return(
                          <>
                          {(parseInt(value.status)===1) &&
                            <Grid item xs={12} sm={12} md={12} spacing={2}>
                                <Typography style={{fontSize:'16px',fontFamily:'cursive',background: '#3488a9',
        padding: '10px', borderRadius: '8px',color: '#fff',width:'95%'}}>{value.message}</Typography>
                            </Grid>
                          }
                          {(parseInt(value.status)===0) &&
                            <Grid item xs={12} sm={12} md={12} spacing={2}>
                                <Typography style={{fontSize:'16px',fontFamily:'cursive',background: 'rgb(52 169 129)',
        padding: '10px', borderRadius: '8px',color: '#fff',width:'95%',float:'right'}}>{value.message}</Typography>
                            </Grid>
                          }
                        </>
                        )
                    }))}
                   
                    {(isLoading===true) &&
                      <Grid item xs={12} sm={12} md={12} spacing={2}>
                          <Stack spacing={1}>
                              <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                          </Stack>
                      </Grid>
                    }
                    
                </Grid>
                </DialogContent>
                <DialogActions>
                  <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-reply">Reply</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-reply"
                        type='text'
                        onChange = {(event)=>handleSetMessage(event.target.value)}
                        onKeyDown={(e)=>handleEnterPress(e)}
                        value={message}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="outlined-adornment-reply"
                              onClick={handleSendMessage}
                              edge="end"
                            >
                            <Send />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Send"
                      />
                    </FormControl>
                </DialogActions>
        </Dialog>
      </>
  );
}
const style = {
    boxShadow:'1px 5px 10px 2px #333',
    padding: '10px 20px 20px 20px',
  };
export default ViewInbox;