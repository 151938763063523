import { Autocomplete, Avatar, Box, Button, ButtonGroup, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import AppDropDown from '../AppDropDown';
import BasicDatePicker from '../DatePicker';
import AppUtils from '../../AppUtils';
import PaymentTypes from '../settings/payment-types/PaymentTypes';
import ExamTypes from '../settings/exam/ExamTypes';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding:'25px'
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

function Marks(props) {
   const [pending_type,setPendingType] = React.useState('in');
   const [payment_type,setPaymentType] = React.useState('');
   const [forMonth,setForMonth] = React.useState(new Date());
   const [page,setPage] = React.useState(0);
   const [pending_report_list,setTransactionList] = React.useState([]);
   const [isGenerating,setIsGenerating] = React.useState(false);
   const [isLoadMore,setIsLoadMore] = React.useState(false);
   const [isAllRecordLoaded,setIsAllRecordLoaded] = React.useState(false);
   const [exam_type,setExamType] = React.useState(0);
   const [is_exam_type_selected,setIsExamType] = React.useState(false);
   
   const handleChangePaymentType = (id,value)=>{
    setPaymentType(value);
   }
   
  const handleChangeForMonth = (id,date) =>{
    setForMonth(date);
  }
 
  const handleResetList = () =>{
    setPage(0);
    let trans = [];
    setTransactionList(trans);
    setIsGenerating(false);
    setIsLoadMore(false);
    setIsAllRecordLoaded(false);
  }
  
  const handleGenerateReport = () =>{
    setIsGenerating(true);
    handleLoadReport();
  }
  const handleLoadMore = () =>{
    setIsLoadMore(true);
    handleLoadReport();
  }
  const handleLoadReport = () =>{
    
    let pendingReportList = [...pending_report_list];
    let next_page = page+1;
    setPage(next_page);
    let data = {page:next_page,forMonth:forMonth,payment_type:payment_type,pending_type:pending_type};
     APP_HTTP.REQUEST('reports/pending_payments',data,true,true,false,true).then((response) => {
      if(next_page===1){
        setIsGenerating(false);
      }else{
        setIsLoadMore(false);
      }
      let trans_list = response.data.response;
      if(trans_list.length>0){
        for (let index = 0; index < trans_list.length; index++) {
          const element = trans_list[index];
          pendingReportList.push(element);
        }
        setTransactionList(pendingReportList);
      }else{
        setIsAllRecordLoaded(true);
      }
    });
  }
  const handleChangeExamType = (id,value) =>{
    setExamType(value);
    if(value>0){
        setIsExamType(true);
    }else{
        setIsExamType(false);
    }
}
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <h2 style={{margin:'0px'}}>MARKS REPORT</h2>
                    <p style={{margin:'0px',fontSize:'14px'}}>Here, we can see the marks report as per applied filter.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={3} spacing={2}>
                    <ExamTypes show="dropdown" handleChangeData={handleChangeExamType} defaultValue={exam_type}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3} spacing={2}>
                    
                </Grid>
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    
                </Grid>
                <>
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    {(isGenerating===false && pending_report_list.length===0) &&
                      <Button variant='outlined' size='medium' onClick={handleGenerateReport}>GENERATE</Button>
                    }
                    {(isGenerating===true) &&
                      <LoaderButton label="Generating..."  variant='outlined' size='medium'/>
                    }
                    {(isGenerating===false && pending_report_list.length>0) &&
                      <Button variant='outlined' size='medium' onClick={handleResetList}>RESET LIST</Button>
                    }
                </Grid>
                </>
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
               
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                  
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>CONTACT NAME</StyledTableCell>
                            <StyledTableCell>PENDING FEE</StyledTableCell>
                            
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pending_report_list && pending_report_list.map((row,index) => (
                            <StyledTableRow>
                              
                              <StyledTableCell component="th" scope="row">
                                <Typography>
                                {row.sequence} / {row.name}
                                </Typography>
                                <Typography>
                                  {(row.class) &&
                                    <Typography>{row.roll_number}/{row.class}({row.section})</Typography>
                                  }
                                  {(row.staff_type) &&
                                    <Typography>{row.staff_type}</Typography>
                                  }
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                <Typography>
                                ₹{AppUtils.currency_format(row.pending_fee)}
                                </Typography>
                              </StyledTableCell>
                             
                            </StyledTableRow>
                          ))}
                          {(pending_report_list.length===0 && isGenerating===false) &&
                           <StyledTableRow>
                            <StyledTableCell component="th" scope="row" sx={{textAlign:'center'}} colSpan={2}>
                              Please apply fitler to see the report
                            </StyledTableCell>
                          </StyledTableRow>
                          }
                          {(isLoadMore===true) &&
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row" colSpan={2}>
                            <Stack spacing={1}>
                                      <Skeleton variant="rectangular" sx={{width:'100%'}} height={30} />
                                  </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                          }
                           {(isGenerating===true) &&
                            <>
                                {Array.from(Array(5), (e, i) => {
                                    return(
                                      <StyledTableRow>
                                      <StyledTableCell component="th" scope="row" colSpan={2}>
                                      <Stack spacing={1}>
                                                <Skeleton variant="rectangular" sx={{width:'100%'}} height={30} />
                                            </Stack>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                    )
                                    })}
                                
                            </>
                            }
                          {(isAllRecordLoaded===false && pending_report_list.length>0) &&
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row" sx={{textAlign:'center'}} colSpan={4}>
                            {(isLoadMore===false) &&
                              <Button variant='outlined' size='small' onClick={handleLoadMore}>Load More</Button>
                            }
                            {(isLoadMore===true) &&
                              <LoaderButton label="Loading..."  variant='outlined' size='small'/>
                            }
                            </StyledTableCell>
                          </StyledTableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                   
                </Grid>
               
            </Grid>
        </Box>
    </Box>
  );
}
export default Marks;