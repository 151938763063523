import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from "react-redux";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { ButtonGroup, Divider, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';
import LoaderButton from '../LoaderButton';

import { AccountCircleRounded, CloseRounded } from '@mui/icons-material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import BasicDatePicker from '../DatePicker';
import Classes from '../settings/classes/Classes';
import Sections from '../settings/sections/Sections';
import AppDropDown from '../AppDropDown';
import AppRadio from '../AppRadio';
import APP_HTTP from '../../APP_HTTP';
import { handleConfirmDeleteStaff, handleSetStaffInfo, handleToggleEditStaff } from './Action';
import { handleChangeMenu } from '../../actions/Action';
import PrintStaff from './PrintStaff';
import DeleteConfirm from '../DeleteConfirm';
import AppUtils from '../../AppUtils';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#ffffff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

let staffDataInitialState = {
    update_id : 0,
    staff_type_id : 0,
    staff_name : '',
    gender : 'male',
    dob : new Date(),
    mobile_number : '',
    alt_mobile_number : '',
    email : '',
    city : '',
    state : 'Bihar',
    pincode : '',
    locality : '',
    joining_date : new Date(),
    employement_type : 'permanent',
    specialization : [],
    salary : '',
    salary_type : 'monthly',
    status : 'active',
}

const StaffProfile = (props)=> {
    let [image_url,setImageURL] = React.useState('');
    let [is_print,setIsPrint] = React.useState(false);
    let [current_sequence,setCurrentSequence] = React.useState('');
    let [isSaveClicked,setIsSaveClicked] = React.useState(false);
    const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
    let [staffData,setStaffData] = React.useState(staffDataInitialState);
    const state = useSelector((state) => state);
    const is_delete_item = state.is_delete_item;
    const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            
            initialized.current = true;
            if(props.staff_data){
                let staff_data = props.staff_data;
                if(staff_data.is_db===undefined){
                    setStaffData(staff_data);
                    setCurrentSequence(staff_data.staff_sequence);
                    setImageURL(staff_data.photo);
                }else{
                    APP_HTTP.REQUEST('staff/get_staff_on_id',{id:staff_data.staff_id},true,true).then((response) => {
                        let resp = response.data.response;
                        setStaffData(resp);
                        setCurrentSequence(resp.staff_sequence);
                        setImageURL(resp.photo);
                        dispatch(handleSetStaffInfo(resp));
                    });
                }
                //handleChangeData('gender',staff_data.gender);
                //handleChangeData('gender',staff_data.gender);
            }
        }
       
    },[props])

    const states = state.states;
    const gender_list = [{key:'male',value:'Male'},{key:'female',value:'Female'}];
    let state_list = [];
    for (let index = 0; index < states.length; index++) {
        const state_name = states[index];
        let list_item = {key:state_name,value:state_name};
        state_list.push(list_item);
    }
    const handleChangeData = () =>{
        
    }
    const handleEditStaff = () =>{
        let staff_info = staffData;
        let staff_id = staff_info.staff_id;
        dispatch(handleChangeMenu('staff','edit/'+staff_id,'Update Staff',false));
        dispatch(handleToggleEditStaff(true));
        dispatch(handleSetStaffInfo(staff_info));
    }
    const handleDeleteStaff = () =>{
        let staffValue = staffData;
        let staff_id = staffValue.staff_id;
        let delData = {id:staff_id,target:'staff'};
        setDeleteData(delData);
        dispatch(handleConfirmDeleteStaff(true));
    }
  
    const handlePrintStaff = ()=>{
        setIsPrint(true);
        setTimeout(() => {
          setIsPrint(false);
        }, 200);
    }
    const handleModifyDeletedStatus = ()=>{
        let pdata = staffData;
        pdata.status = 0;
        setStaffData(pdata);
      }
  return (
    <div id="view-profile">
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
            {(staffData && staffData.staff_name!==undefined && staffData.staff_sequence!==undefined) &&
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
            <Grid item xs={12} sm={12} md={12} spacing={2}>
            <Grid container spacing={2} className="mrtop-10">  
                    <Grid item xs={12} sm={12} md={12} sx={{color: '#3c3c3c',padding: '5px', borderBottom:'1px solid #d7d7d7'}}>
                        <Grid container spacing={2} className="mrtop-10"> 
                            
                            <Grid item xs={12} sm={12} md={1}>
                            <Typography sx={{height:'80px',width:'80px',border:'2px solid #d7d7d7',margin:'0 auto',borderRadius:'50%',cursor:'pointer'}}>
                                    {(image_url!=='') &&
                                        <img src={image_url} alt="Image" height={'100%'} width={'100%'} style={{borderRadius:'50%'}}/>
                                    }
                                    {(image_url==='') &&
                                        <AccountCircleRounded size="large" sx={{height:'100%',width:'100%',color:'#d7d7d7'}}/>
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} sx={{marginTop:'16px'}}> 
                                
                                <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>{current_sequence} | {staffData.staff_name} | {staffData.staff_type} | {(staffData.gender==='male')?'Male':'Female'}</Typography>
                                <Typography variant='span' sx={{fontSize:'14px',color:'gray'}}>{staffData.mobile_number} {(staffData.alt_mobile_number!=="")?' | '+staffData.alt_mobile_number:''} {(staffData.email!=="")?' | '+staffData.email:''}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                            
                               <Box
                               sx={{
                                 display: 'flex',
                                 '& > *': {
                                   m: 1,
                                 },
                                 float:'right'
                               }}
                             >
                                {(parseInt(staffData.status)===1) &&
                               <ButtonGroup
                                 aria-label="vertical outlined button group"
                               >
                                {(AppUtils.has_access(state,'3_staffs_edit')) &&
                                  <Button key="edit" onClick={handleEditStaff}>Edit</Button>
                                }
                                  {(AppUtils.has_access(state,'3_staffs_delete')) &&
                                  <Button key="delete" onClick={handleDeleteStaff}>Delete</Button>
                                }
                                {(AppUtils.has_access(state,'3_staffs_print')) &&
                                  <Button key="print" onClick={handlePrintStaff}>Print</Button>
                                }
                               </ButtonGroup>
                                }
                            </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Grid container spacing={2}>  
                        <Grid item xs={12} sm={12} md={12}>
                            <h3>Basic Information</h3>
                            <Divider />
                        </Grid>
                      
                        <Grid item xs={12} sm={12} md={3}>
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Date Of Birth</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{staffData.dob_display}</p>
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Alternate Mobile Number</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{staffData.alt_mobile_number}</p>
                           
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Email</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{staffData.email}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>City</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{staffData.city}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>State</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{staffData.state}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Pincode</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{staffData.pincode}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Locality</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{staffData.locality}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>Advanced Information</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Joining Date</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{staffData.joining_date_display}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Specialization</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{staffData.specialization_view}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Salary</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>₹{staffData.salary}/{staffData.salary_type}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Status</p>
                            <p style={{margin:'0px',fontSize:'16px'}}>{(staffData.status==='1' || staffData.status==='active')?'Active':'Inactive'}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>Documents</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className='mrtop-15'>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(staffData.aadharc && staffData.aadharc!=='') &&
                                                    <>
                                                        <img height="50" width="40" src={staffData.aadharc} />
                                                        <Typography variant="span" sx={{marginLeft:'5px'}}>Aadhar Card </Typography>
                                                        <a style={{marginLeft:'5px'}} href={staffData.aadharc} download={true}>
                                                            Download
                                                        </a>
                                                    </>
                                                }
                                                {(staffData.aadharc===undefined || staffData.aadharc==='') &&
                                                    <>
                                                        <CloseRounded sx={{color:'red',fontSize:'14px'}}/>
                                                        <Typography variant="span" sx={{marginLeft:'5px'}}>Aadhar Card </Typography>
                                                    </>
                                                }
                                            </StyledTableCell>      
                                            
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(staffData.dobc && staffData.dobc!='') &&
                                                <>
                                                    <img height="50" width="40" src={staffData.dobc} />
                                                    <Typography variant="span" sx={{marginLeft:'5px'}}>Date of Birth Certificate</Typography>
                                                    <a style={{marginLeft:'5px'}} href={staffData.dobc} download={true}>
                                                    Download
                                                        </a>
                                                </>
                                                }
                                                {(staffData.dobc===undefined || staffData.dobc==='') &&
                                                   <>
                                                    <CloseRounded sx={{color:'red',fontSize:'14px'}}/>
                                                    <Typography variant="span" sx={{marginLeft:'5px'}}>Date of Birth Certificate</Typography>
                                                   </>
                                                }
                                            </StyledTableCell>      
                                                
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(staffData.experiencec && staffData.experiencec!='') &&
                                                <>
                                                    <img height="50" width="40" src={staffData.experiencec} />
                                                    <Typography variant="span" sx={{marginLeft:'5px'}}>Experience Letter</Typography>
                                                    <a style={{marginLeft:'5px'}} href={staffData.experiencec} download={true}>
                                                    Download
                                                        </a>
                                                </>
                                                }
                                                {(staffData.experiencec===undefined || staffData.experiencec==='') &&
                                                <>
                                                   <CloseRounded sx={{color:'red',fontSize:'14px'}}/>
                                                    <Typography variant="span" sx={{marginLeft:'5px'}}>Experience Letter</Typography>
                                                </>
                                                }
                                            </StyledTableCell>      
                                               
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(staffData.payslipc && staffData.payslipc!='') &&
                                                <>
                                                    <img height="50" width="40" src={staffData.payslipc} />
                                                    <Typography variant="span" sx={{marginLeft:'5px'}}>Last Pay Slip</Typography>
                                                    <a style={{marginLeft:'5px'}} href={staffData.payslipc} download={true}>
                                                    Download
                                                        </a>
                                                </>
                                                }
                                                {(staffData.payslipc===undefined || staffData.payslipc==='') &&
                                                <>
                                                   <CloseRounded sx={{color:'red',fontSize:'14px'}}/>
                                                   <Typography variant="span" sx={{marginLeft:'5px'}}>Last Pay Slip</Typography>
                                                </>
                                                }
                                            </StyledTableCell>  
                                        </StyledTableRow>       
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                </Grid>
            </Grid>
        </Grid>
            }
        </Box>
        {(is_delete_item) &&
          <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" handleModifyDeletedStatus={handleModifyDeletedStatus}/>
        }
        {(is_print) &&
        <PrintStaff staff_data={staffData} />
        } 
    </div>
  );
}
export default StaffProfile;