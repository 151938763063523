import * as React from 'react';
const PrintHeader = (props)=> {
   
  return (
    <thead>
        {(props.header_data && props.header_data.info) &&
        <>
        <tr>
            <th style={{borderBottom:'none',textAlign:'center',paddingBottom:'1px'}} colSpan={(props.colSpan)?props.colSpan:5}>
                <img src={props.header_data.info.photo} alt="image" height={'90'}/>
            </th>
        </tr>
        <tr>
           
            <th style={{textAlign:'center',borderTop:'none',paddingTop:'1px'}} colSpan={(props.colSpan)?props.colSpan:5}>
                <h4 style={{fontSize:'20px',fontWeight:'bold'}}>
                {props.header_data.info.school_name}
                </h4>
                <h4 style={{fontSize:'15px',fontWeight:'bold'}}>
                {props.header_data.info.mobile_number} | {props.header_data.info.alt_mobile_number}
                </h4>
                <h4 style={{fontSize:'15px',fontWeight:'bold'}}>
                {props.header_data.info.email}
                </h4>
                <h4 style={{fontSize:'15px',fontWeight:'bold',borderLeft:'none'}}>
                {props.header_data.info.locality}, {props.header_data.info.city}, {props.header_data.info.state},{props.header_data.info.pincode}
                </h4>
            </th>
        </tr>
        </>
        }
    </thead>
  );
}
export default PrintHeader;