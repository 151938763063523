import axios from 'axios';
let app_api = 'https://school.scoogro.com/SchoolAppAPI';
const uuid =()=>{
    var nav = window.navigator;
    var screen = window.screen;
    var guid = nav.mimeTypes.length;
    guid += nav.userAgent.replace(/\D+/g, '');
    guid += nav.plugins.length;
    guid += screen.height || '';
    guid += screen.width || '';
    guid += screen.pixelDepth || '';
    return guid;
}
let cancelToken;;
const REQUEST = async (api,data,headers=false,is_logged_in=false,is_form_data=false,is_cancel_last_request=false) => {
    if(is_cancel_last_request==true){

        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.");
            
            //return false;
        }
        cancelToken = axios.CancelToken.source();
    }
    let school_info = JSON.parse(localStorage.getItem('MnzYs4pVGL'));
    if(is_logged_in){
        if(is_form_data===false){
            data.hash = school_info.hash;
            data.user_id = school_info.id;
        }else{
            data.append('hash',school_info.hash);
            data.append('user_id',school_info.id);
        }
    }
    if('school/create_school'===api || 'school/logout'===api || 'school/login'===api){
        if(is_form_data===false){
            data.uuid = uuid();
        }else{
            data.append('uuid',uuid());
        }
    }
    
   
    
    let http_header = [];
    if(headers){
        http_header = {
            headers: {
                'Enc-Key': uuid(),
                'Enc-Token': school_info.hash,
                'App-UserID': school_info.id,
            }
        }
        if(is_cancel_last_request===true){
            let cancelledToken = cancelToken.token;
            http_header.cancelToken=cancelledToken;
        }
    }
    
    let api_req  = app_api+'/'+api;
    try{
        const response = await axios.post(
            api_req,
            data,
            http_header
        );
        if(response.data==='401 - Unauthorize Request'){
            localStorage.removeItem('MnzYs4pVGL');
            window.location.href = '/login';
        }
        return response;
    }catch(err){
        return false;
    }
};
export default {REQUEST};