import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from "react-redux";
import AppUtils from '../../AppUtils';
import PaymentList from './PaymentList';
import { handleChangeMenu } from '../../actions/Action';
import { Button, ButtonGroup, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import DemandBillList from './DemandBills';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PaymentHome() {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const state = useSelector((state) => state);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(handleChangeMenu('payments','','Payments',true));
  };
 
  const handleGenerateDemandBill = () =>{
    let add_main_menu = 'payment';
    let add_sub_menu = 'add/demand-bill';
    let add_label = 'Create Demand Bill';
    dispatch(handleChangeMenu(add_main_menu,add_sub_menu,add_label,false));
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2} className="mrtop-10 mrbtm-30">
        <Grid item xs={12} sm={12} md={12} spacing={2} sx={{textAlign:'right'}}>
          <ButtonGroup aria-label="vertical outlined button group">
              <Button variant='outlined' size='small' onClick={handleGenerateDemandBill}>Generate Demand Bill</Button>
              {/* <Button variant='outlined' size='small' onClick={handleAddPendingPayment}>Add Opening Balance</Button> */}
          </ButtonGroup>
        </Grid>
      </Grid>
      <Divider />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        
          <Tab label="All Payments" {...a11yProps(0)} />
          <Tab label="Payment IN(+)" {...a11yProps(1)} />
          <Tab label="Payment OUT(-)" {...a11yProps(2)} />
          <Tab label="Demand Bills" {...a11yProps(3)} />
       
        </Tabs>
      </Box>
     
        {(value===0) &&
          <CustomTabPanel value={value} index={0}>
            <PaymentList type='all'/>
          </CustomTabPanel>
        }
       
      {(value===1) &&
        <CustomTabPanel value={value} index={1}>
          <PaymentList type='in'/>
        </CustomTabPanel>
      }
     
      {(value===2) &&
        <CustomTabPanel value={value} index={2}>
          <PaymentList type='out'/>
        </CustomTabPanel>
      }
      {(value===3) &&
        <CustomTabPanel value={value} index={3}>
          <DemandBillList type='demand_bills'/>
        </CustomTabPanel>
      }
    </Box>
  );
}
