import { Box, Button, ButtonGroup, Dialog, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import ExamTypes from '../settings/exam/ExamTypes';

import AppMultiSelectCB from '../AppMultiSelectCB';
import PrintAllocatedRoom from './PrintAllocatedRoom';
import AppUtils from '../../AppUtils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
     
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
let classRoomData = [];
function ClassRoomAllocation(props) {
    const state = useSelector((state) => state);
    const class_list = state.class_list;
    const subject_list = state.subject_list;
    const is_delete_item = state.is_delete_item;
    const [is_exam_type_selected,setIsExamType] = React.useState(false);
    const [exam_type,setExamType] = React.useState(0);
    const [show_skelton,setShowSkelton] = React.useState(false);
    const [examTypeData,setExamTypeData] = React.useState([]);
    const [is_print,setIsPrint] = React.useState(false);
    const [show_print_button,setShowPrintButton] = React.useState(false);
    //const [examTypeDataUpdated,setExamTypeDataUpdate] = React.useState([]);
    const [isSaveClicked,setIsSaveClicked] = React.useState(false);
    const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
        }
    },[])
    const handleChangeExamType = (id,value) =>{
      setExamType(value);
      if(value>0){
          setIsExamType(true);
          handleGetExamOnType(value);
      }else{
          setIsExamType(false);
          setExamTypeData([]);
          classRoomData = [];
      }
    }
    const handleGetExamOnType = (value)=>{
      setShowSkelton(true);
      APP_HTTP.REQUEST('exam/get_scheduled_exam_on_type',{exam_type:value},true,true).then((response) => {
        let resp = response.data.response;
        let isp = response.data.is_show_print;
        setExamTypeData(resp);
        //setExamTypeDataUpdate(resp);
        classRoomData = resp.scheduled_data;
        setShowSkelton(false);
        setShowPrintButton(isp);
      });
    }
    const handleChangeData = (id,value) =>{
      let exData = classRoomData;
      if(exData.length===0){
        classRoomData = examTypeData.scheduled_data;
      }
      const keys = id.split("-");
      const date_index = keys[0];
      const room_index = keys[1];
      exData[date_index].class_rooms[room_index].selected_invigilator=value;
      classRoomData = exData;
    }
    
    const handleSetClassRoomData = (main_index,parent_index,sub_type_key,sub_type_idx,key,value) =>{
      let exData = classRoomData;
      if(exData.length===0){
        classRoomData = examTypeData;
      }
      exData[main_index].class_rooms[parent_index][sub_type_key][sub_type_idx][key]=value;
      classRoomData = exData;
    }
    const handleSaveInformation = (type) =>{
      setIsSaveClicked(true);
      APP_HTTP.REQUEST('exam/allocate_exam_room',{exam_type:exam_type,data:classRoomData},true,true).then((response) => {
        let resp = response.data.response;
        setIsSaveClicked(false);
        setShowPrintButton(true);
      });
    }
    const handlePrint = () =>{
      setIsPrint(true);
      setTimeout(() => {
       setIsPrint(false);
      }, 5000);
    }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <h2 style={{margin:'0px'}}>ALLOCATION OF EXAMINATION HALL</h2>
                    
                    <p style={{margin:'0px',fontSize:'14px'}}>Allocate examination hall and class invigilator with class roll number from and to.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                {(AppUtils.has_access(state,'8_exam_hall_allocation_view')) &&
                <Grid item xs={12} sm={12} md={3} spacing={2}>
                    <ExamTypes show="dropdown" handleChangeData={handleChangeExamType} defaultValue={exam_type}/>
                </Grid>
                }
                <Grid item xs={12} sm={12} md={9} spacing={2}>
                  {(show_print_button) &&
                  <>
                  {(AppUtils.has_access(state,'8_exam_hall_allocation_print')) &&
                    <Button variant="outlined" onClick={handlePrint}>Print</Button>
                  }
                  </>
                  }
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                 {(show_skelton===false) && 
                    <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                      <TableBody>
                        {examTypeData && examTypeData.scheduled_data && examTypeData.scheduled_data.map((value,index)=>{
                            return (
                                <>
                              <StyledTableRow>
                                <StyledTableCell colSpan={3}>
                                  <h2 style={{margin:'0px'}}>
                                    {value.date}
                                  </h2>
                                </StyledTableCell>
                              </StyledTableRow>
                                {value.class_rooms && value.class_rooms.map((room,ridx)=>{
                                    return (
                                    <>
                                      <StyledTableRow >
                                        <StyledTableCell sx={{paddingLeft:'50px'}}>
                                          <h3 style={{margin:'0px',fontSize:'18'}}>
                                            {room.class_room_info.name}
                                          </h3>
                                        </StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>
                                          <Typography>
                                          <AppMultiSelectCB label="Class invigilator" list_item={room.invigilator} id={index+'-'+ridx} handleChangeData={handleChangeData}  defaultValue={room.selected_invigilator}/>
                                          </Typography>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                      {room.class_list && room.class_list.map((cls,clidx)=>{
                                      return (
                                            <StyledTableRow>
                                              <StyledTableCell sx={{paddingLeft:'100px'}}>
                                                <h4 style={{margin:'0px',fontSize:'16'}}>
                                                  Class {cls.name}
                                                </h4>
                                              </StyledTableCell>
                                              <StyledTableCell>
                                              <TextField
                                                label="Roll No From"
                                                size="small"
                                                id="roll_no_from"
                                                fullWidth
                                                onChange={(event)=>handleSetClassRoomData(index,ridx,'class_list',clidx,'roll_from',event.target.value)}
                                                defaultValue={cls.roll_from}
                                                />
                                              </StyledTableCell>
                                              <StyledTableCell>
                                              <TextField
                                                label="Roll No To"
                                                size="small"
                                                id="roll_no_to"
                                                fullWidth
                                                onChange={(event)=>handleSetClassRoomData(index,ridx,'class_list',clidx,'roll_to',event.target.value)}
                                                defaultValue={cls.roll_to}
                                                />
                                              </StyledTableCell>
                                            </StyledTableRow>
                                            
                                          )
                                      })}
                                    </>
                                    )
                                })}
                              </>
                            )
                        })}
                        {(AppUtils.has_access(state,'8_exam_hall_allocation_add') || AppUtils.has_access(state,'8_exam_hall_allocation_edit')) &&
                        <>
                          {(examTypeData && examTypeData.scheduled_data && examTypeData.scheduled_data.length>0) &&
                            <StyledTableRow>
                              <StyledTableCell colSpan={3} sx={{textAlign:'right'}}>
                              {(isSaveClicked===false) &&
                                  <Button variant="contained" onClick={() => handleSaveInformation('save')}>Save Exam Hall Allocation</Button>
                              }
                              {(isSaveClicked===true) &&
                                  <LoaderButton label="Schedule Exam"/>
                              }
                              </StyledTableCell>
                            </StyledTableRow>
                          }
                        </>
                        }
                      </TableBody>
                    </Table>
                    </TableContainer>
                  }
                  {(show_skelton===true) &&
                  
                    <Stack spacing={1}>
                      <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                      <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                      <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                      <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                    </Stack>
                  
                  }
                </Grid>
            </Grid>
        </Box>
        {(is_print===true) &&
          <PrintAllocatedRoom data={examTypeData}/>
        }
    </Box>
  );
}
export default React.memo(ClassRoomAllocation);