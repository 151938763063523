import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';
const today = dayjs();
export default function BasicDatePicker(props) {
  const [selected_date,setDate] = React.useState(new Date());
  const initialized = React.useRef(false);
  React.useEffect(()=>{
    if (!initialized.current) {
        initialized.current = true;
        if(props.defaultValue && props.defaultValue!==''){
          let setdate = new Date(props.defaultValue);
          setDate(setdate);
        }
    }
  },[props])
  const handleSetDate = (date) =>{
    setDate(date);
    if(props.handleChangeData){
      props.handleChangeData(props.id,date);
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {(props.is_show_time===undefined) &&
        <DatePicker value={dayjs(selected_date)} label={props.label} slotProps={{ textField: { size: 'small',fullWidth: true,inputProps:{ className:props.className,id:props.id}}}}  onChange={(newValue) => handleSetDate(newValue)}  format={(props.format)?props.format:'D/M/YYYY'} views={props.views}/>
      }
      {(props.is_show_time===true) &&
         <MobileDateTimePicker value={dayjs(selected_date)}  label={props.label} slotProps={{ textField: { size: 'small',fullWidth: true,inputProps:{ className:props.className,id:props.id}}}}  onChange={(newValue) => handleSetDate(newValue)} format='D/M/YYYY HH:mm'/>
      }
    </LocalizationProvider>
  );
}