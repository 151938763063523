import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AppDropDown from '../AppDropDown';
import APP_HTTP from '../../APP_HTTP';

export default function TransportDropdown(props) {
  const [defaultValue, setDropdownValue] = React.useState(props.defaultValue);
  const [transportList, setTransportList] = React.useState([]);
  const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            APP_HTTP.REQUEST('transport/get_all_transports',{},true,true).then((response) => {
                let resp = response.data.response;
                setTransportList(resp);
            });
        }
    },[props])

  const handleChangeData = (id,value) =>{
    props.handleChangeData(id,value);
    setDropdownValue(value);
  }
  return (
    <AppDropDown label="Select Transport" id="transport_dd" defaultValue={defaultValue} list_item={transportList} size="small" className="transport-data" handleChangeData={handleChangeData}/>
  );
}