import { Box, Button, ButtonGroup, Dialog, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import BasicDatePicker from '../DatePicker';
import { Check, Close, Edit, Print } from '@mui/icons-material';
import ExamTypes from '../settings/exam/ExamTypes';
import Classes from '../settings/classes/Classes';
import Subjects from '../settings/subjects/Subjects';
import DeleteConfirm from '../DeleteConfirm';
import { handleConfirmDeleteScheduledExam } from './Action';
import PrintScheduledExam from './PrintScheduledExam';
import AppUtils from '../../AppUtils';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
     
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
export default function Schedule(props) {
    const state = useSelector((state) => state);
    const class_list = state.class_list;
    const subject_list = state.subject_list;
    const is_delete_item = state.is_delete_item;
    const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
    const [is_exam_type_selected,setIsExamType] = React.useState(false);
    const [exam_type,setExamType] = React.useState(0);
    const [is_class_selected,setIsClass] = React.useState(false);
    const [className,setClass] = React.useState(0);
    const [is_call_service, setIsCallService] = React.useState(false);   
    const [subjectList, setSubjectList] = React.useState([]);   
    const [scheduledList, getScheduledList] = React.useState([]);   
    const [isSaveClicked,setIsSaveClicked] = React.useState(false);
    const [update_id,setIsUpdate] = React.useState(0);
    const [is_refresh_exam_type,setRefereshExamType] = React.useState(false);
    const [is_loading_scheduled_exam,setIsLoadingScheduledExam] = React.useState(true);
    const [is_scheduled_item_clicked,setScheduledItemClick] = React.useState(false);
    const [is_print,setIsPrint] = React.useState(false);
    const [print_data,setPrintData] = React.useState([]);
    const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            handleGetScheduledExam();
            if(subject_list.length===0){
                setIsCallService(true); 
            }
            initialized.current = true;
        }
    },[subject_list])
    const handleChangeExamType = (id,value) =>{
        setExamType(value);
        if(value>0){
            setIsExamType(true);
        }else{
            setIsExamType(false);
        }
    }
    const handleDeleteItem = (id)=> {
      let paymentValue = scheduledList[id];
      let exam_schedule_id = paymentValue.exam_schedule_id;
      
      let delData = {id:exam_schedule_id,index:id,target:'scheduled_exam'};
      setDeleteData(delData);
      dispatch(handleConfirmDeleteScheduledExam(true));
  }
   const handleSelectClass = (id,value) =>{
        let sub_list = state.subject_list;
        
        setClass(value);
        if(value>0){
            setSubjectList([]);
            let send_data = {
              exam_type : exam_type,
              class_id : value,
            };
            APP_HTTP.REQUEST('exam/get_scheduled_exam_on_type_class',send_data,true,true).then((response) => {
              let resp = response.data.response;
              if(resp.length>0){
                setSubjectList(resp);
              }else{
                let slists = [];
                for (let index = 0; index < sub_list.length; index++) {
                    const element = sub_list[index];
                    let prepare_cls = {id:element.id,name:element.name,active:true,ex_date:new Date()};
                    slists.push(prepare_cls);
                }
                setSubjectList(slists);
              }
            });
            setIsClass(true);
        }else{
            setIsClass(false);
            setSubjectList([]);
        }
   }
    const handleMarkSubject = (id,value)=>{
      let subList = [...subjectList];
      subList[id].active = !value;
      setSubjectList(subList);
    }
    const handleSelectExamDate = (id,value) =>{
      let subList = [...subjectList];
      subList[id].ex_date = value;
      setSubjectList(subList);
    }
    const handleSaveInformation = (type) =>{
      let send_data = {
        update_id : update_id,
        exam_type : exam_type,
        class_id : className,
        schedules : subjectList
      };
      setIsSaveClicked(true);
      APP_HTTP.REQUEST('exam/schedule_exam',send_data,true,true).then((response) => {
        let resp = response.data.response;
        setIsSaveClicked(false);
        handleGetScheduledExam();
      });
    }
    const handleGetScheduledExam=()=>{
      setIsLoadingScheduledExam(true);
      APP_HTTP.REQUEST('exam/get_scheduled_exam',{},true,true).then((response) => {
        let resp = response.data.response;
        getScheduledList(resp);
        setIsLoadingScheduledExam(false);
      });
    }
    const handleShowScheduleExam = (index)=>{
      let schedList = [...scheduledList];
      let class_id = schedList[index].class_id;
      let exam_type = schedList[index].exam_type;
      let schedules = schedList[index].schedules;
      setSubjectList([]);
      setRefereshExamType(true);
      setIsClass(false);
      setIsExamType(false);
      setScheduledItemClick(true);
      setTimeout(() => {
        setSubjectList(schedules);
        setRefereshExamType(false);
        setScheduledItemClick(false);
        setExamType(exam_type);
        setClass(class_id);
        setIsExamType(true);
        setIsClass(true);
      }, 100);
    }
    const handleRefreshList = () =>{
      handleGetScheduledExam();
    }
    const handlePrintScheduledExam = (index) =>{
      let schedList = [...scheduledList];
      let pdata = schedList[index];
      setPrintData(pdata);
      setIsPrint(true);
      setTimeout(() => {
        setIsPrint(false);
      }, 200);
    }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                
                <Grid item xs={12} sm={12} md={3} spacing={2}>
                  {(is_refresh_exam_type===false) &&
                    <ExamTypes show="dropdown" handleChangeData={handleChangeExamType} defaultValue={exam_type}/>
                  }
                  {(is_scheduled_item_clicked) &&
                   <Stack spacing={1}>
                   <Skeleton variant="rectangular" sx={{width:'100%'}} height={40} />
                  </Stack>
                  }
                </Grid>
                
                    <Grid item xs={12} sm={12} md={3} spacing={2}>
                      {(is_exam_type_selected) &&
                        <Classes show="dropdown" className="student-data" handleChangeData={handleSelectClass} defaultValue={className}/>
                      }
                      {(is_scheduled_item_clicked) &&
              
                        <Stack spacing={1}>
                        <Skeleton variant="rectangular" sx={{width:'100%'}} height={40} />
                        </Stack>
                        
                      }
                    </Grid>
                  
              
                
                

                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                {(is_call_service===true) &&
                    <Subjects show="service"/>
                }
                
                
                <Grid item xs={12} sm={12} md={6} spacing={2}>
                {(AppUtils.has_access(state,'7_schedule_exam_view')) &&
                <>
                {(is_class_selected || is_scheduled_item_clicked) &&
                    <>
                      <h3 style={{margin:'0px'}}>Schedule Exam Timing</h3>
                      {(subjectList.length>0) &&
                        <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell></StyledTableCell>
                              <StyledTableCell>SUBJECT NAME</StyledTableCell>
                              <StyledTableCell>EXAM DATE & TIME</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {subjectList && subjectList.map((value,index)=>{
                                return (
                                  <StyledTableRow>
                                    <StyledTableCell>
                                      <Typography>
                                      <Checkbox defaultChecked={value.active} size="small" onChange={()=>handleMarkSubject(index,value.active)}/>
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Typography>
                                        {value.name}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {(value.ex_date) &&
                                        <BasicDatePicker is_show_time={true} label="Exam Date & Time" size="small" id={index} defaultValue={value.ex_date} handleChangeData={handleSelectExamDate}/>   
                                      }   
                                    </StyledTableCell>
                                  
                                  </StyledTableRow>
                                )
                            })}
                            {(AppUtils.has_access(state,'7_schedule_exam_add') || AppUtils.has_access(state,'7_schedule_exam_edit')) &&
                            <StyledTableRow>
                              <StyledTableCell colSpan={3} sx={{textAlign:'right'}}>
                              
                                {(isSaveClicked===false) &&
                                    <Button variant="contained" onClick={() => handleSaveInformation('save')}>Schedule Exam</Button>
                                }
                                {(isSaveClicked===true) &&
                                    <LoaderButton label="Schedule Exam"/>
                                }
                              </StyledTableCell>
                            </StyledTableRow>
                              }
                          </TableBody>
                        </Table>
                        </TableContainer>
                      }
                      {(subjectList.length===0) &&
                        <Stack spacing={1}>
                          <Skeleton variant="rectangular" sx={{width:'100%'}} height={60} />
                          <Skeleton variant="rectangular" sx={{width:'100%'}} height={60} />
                          <Skeleton variant="rectangular" sx={{width:'100%'}} height={60} />
                          <Skeleton variant="rectangular" sx={{width:'100%'}} height={60} />
                          <Skeleton variant="rectangular" sx={{width:'100%'}} height={60} />
                          <Skeleton variant="rectangular" sx={{width:'100%'}} height={60} />
                        </Stack>
                      }
                    </>
                }
                </>
                }
                {(is_class_selected===false) &&
                  <h3 style={{margin:'0px'}}>Select Exam Type and then Class to Schedule Exam</h3>
                }
                </Grid>
                
                <Grid item xs={12} sm={12} md={6} spacing={2}>
                  <h3 style={{margin:'0px'}}>Scheduled Exam List</h3>
                  <Divider />
                  {(is_loading_scheduled_exam===false && scheduledList.length>0) &&
                  <Stack spacing={2} sx={{ width: '100%' }}>
                    {(scheduledList && scheduledList.map((value,index)=>{
                      return (
                        <MuiAlert elevation={6} variant="filled" severity="success" sx={{width:'100%',cursor:'pointer'}}>
                          
                            <span>{value.exam_type_name} / {value.class_name} / {value.date} </span>
                            {(AppUtils.has_access(state,'7_schedule_exam_delete')) &&
                            <Close size="small" sx={{fontSize:'20px',float:'right',cursor:'pointer'}} onClick={()=>handleDeleteItem(index)}/>
                            }
                            {(AppUtils.has_access(state,'7_schedule_exam_edit')) &&
                            <Edit size="small" sx={{fontSize:'20px',float:'right',cursor:'pointer'}} onClick={()=>handleShowScheduleExam(index)}/>
                            }
                            {(AppUtils.has_access(state,'7_schedule_exam_print')) &&
                            <Print size="small" sx={{fontSize:'20px',float:'right',cursor:'pointer'}} onClick={()=>handlePrintScheduledExam(index)}/>
                            }
                          
                        </MuiAlert>
                      )
                    }))}
                  
                  </Stack>
                  }
                  {(is_loading_scheduled_exam===true) &&
                    <Stack spacing={1}>
                      {(scheduledList.length>0 && scheduledList.map((value,index)=>{
                      return(
                        <Skeleton variant="rectangular" sx={{width:'100%'}} height={40} />
                        )
                      }))}
                      {(scheduledList.length>0) &&
                      <Skeleton variant="rectangular" sx={{width:'100%'}} height={40} />
                      }
                    </Stack>
                  }
                  {(is_loading_scheduled_exam===false && scheduledList.length===0) &&
                    <h4>No Exam Scheduled Yet!</h4>
                  }
                </Grid>
                
            </Grid>
        </Box>
        {(is_delete_item) &&
          <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" handleRefreshList={handleRefreshList} />
        }
        {(is_print) &&
        <PrintScheduledExam scheduled_exam_data={print_data} />
      }
    </Box>
  );
}