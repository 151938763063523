import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Divider, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AddCircleOutline, ArrowRightAlt, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import { useSelector, useDispatch } from "react-redux";
import { handleConfirmDeletePaymentType, handleOpenAddPaymentType,handleSetPaymentTypeList } from './Action.jsx';
import DeleteConfirm from '../../DeleteConfirm.jsx';
import AppDropDown from '../../AppDropDown.jsx';
import APP_HTTP from '../../../APP_HTTP.jsx';
import CreatePaymentType from './CreatePaymentType.jsx';
import AppUtils from '../../../AppUtils.jsx';

const PaymentTypes = (props) => {
    const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
    const [class_fee,setClassFee] = React.useState({});
    const [is_loading,setLoading] = React.useState(true);
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const is_open_add_payment_type = state.is_open_add_payment_type;
    const is_delete_item = state.is_delete_item;
    const payment_type_list = state.payment_type_list;
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if(payment_type_list.length===0){
            if (!initialized.current) {
                initialized.current = true;
                APP_HTTP.REQUEST('settings/get_payment_type_list',{},true,true).then((response) => {
                    let resp = response.data;
                    let payment_type_list = resp.response;
                    setLoading(false);
                    dispatch(handleSetPaymentTypeList(payment_type_list));
                });
            }
        }
    },[dispatch,payment_type_list])

    const list_item = [{key:0,value:'Select Payment Type'}];
    for (let index = 0; index < payment_type_list.length; index++) {
        const element = payment_type_list[index];
        let ptype_item = {key:element.id,value:element.name};
        list_item.push(ptype_item)
    }
    const [payment_typeValue,setPaymentTypeEdit] = React.useState({id:0,name:'',is_edit:true});
    
    const handleDeleteItem = (id)=> {
        let ptypeValue = payment_type_list[id];
        let ptypeId = ptypeValue.id;
        
        let delData = {id:ptypeId,index:id,target:'payment_type'};
        setDeleteData(delData);
        dispatch(handleConfirmDeletePaymentType(true));
    }
    const handleEditItem = (id)=>{
        let ptypeValue = payment_type_list[id];
        let cls_fee = ptypeValue.class_fee;
        setClassFee(cls_fee);
        if(ptypeValue.is_edit===undefined){
            ptypeValue['is_edit'] = true;
        }else {
            ptypeValue.is_edit = true;
        }
        if(ptypeValue.update_id===undefined){
            ptypeValue['update_id'] = id;
        }else{
            ptypeValue.update_id = id;
        }
        setPaymentTypeEdit(ptypeValue);
        dispatch(handleOpenAddPaymentType(true));
    }
    const openAddPaymentType = () =>{
        setPaymentTypeEdit({id:0,name:'',is_edit:false});
        dispatch(handleOpenAddPaymentType(true));
    }
  return (
    <>
    {(props.show==='list') &&
    <>
        <Box sx={{ flexGrow: 1 }} payment_typeName="MainContainer">
            <Grid container spacing={2} payment_typeName="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Typography variant='h4' sx={{backgroundColor: 'rgba(25, 118, 210, 0.08)',marginTop: '8px', padding: '3px 10px',color:'rgba(0, 0, 0, 0.54)'}}>
                        Payment Types
                        {(AppUtils.has_access(state,'29_payment_types_add')) &&
                        <AddCircleOutline sx={{float:'right',marginTop:'3px',cursor:'pointer'}} fontSize='large' onClick={openAddPaymentType}/>
                        }
                    </Typography>
                </Grid>
                {(AppUtils.has_access(state,'29_payment_types_view')) &&
                <Grid item xs={12} sm={12} md={12} spacing={2} style={{paddingTop:'8px'}}>
                <Divider />
                    <List>
                        {(payment_type_list.map((value,index)=>{
                            return (
                                <>
                                <ListItem key={value.name} disablePadding>
                                    <ListItemButton>
                                        <ListItemText primary={value.name} />
                                        {(parseInt(value.id)!==3) &&
                                            <>
                                                {(AppUtils.has_access(state,'29_payment_types_delete')) &&
                                                    <Delete fontSize='small' onClick={() =>handleDeleteItem(index)}/>
                                                }
                                                {(AppUtils.has_access(state,'29_payment_types_edit')) &&
                                                    <Edit  fontSize='small' onClick={() =>handleEditItem(index)}/>
                                                }
                                            </>
                                        }
                                    </ListItemButton>
                                </ListItem>
                                <Divider/>
                            </>
                            )
                        }))}
                        {(is_loading===false && payment_type_list.length===0) &&
                             <ListItem key={"No Record Found"} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"No Record Found"} />
                                </ListItemButton>
                            </ListItem>
                        }
                        {(is_loading===true && payment_type_list.length===0) &&
                             <ListItem key={"Loading..."} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"Loading..."} />
                                </ListItemButton>
                            </ListItem>
                        }
                    </List>
                
                </Grid>
                }
            </Grid>
        </Box>
       {(is_open_add_payment_type) && 
        <CreatePaymentType payment_typeValue={payment_typeValue} class_fee={class_fee}/>
       }
        {(is_delete_item) &&
        <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" />
       }
    </>
    }
    {(props.show==='dropdown') &&
    <>
        {(list_item.length>0) &&
            <AppDropDown label="PaymentType" id={(props.id!==undefined)?props.id:'payment_mode'}  defaultValue={(props.defaultValue)?parseFloat(props.defaultValue):0} list_item={list_item} size="small" payment_typeName={props.payment_typeName} handleChangeData={props.handleChangeData}/>
        }
    </>
    }
    </>
  );
}

export default PaymentTypes;