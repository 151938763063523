import { Autocomplete, Avatar, Box, Button, ButtonGroup, Checkbox, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { DoubleArrow, DoubleArrowOutlined, FlashOnRounded } from '@mui/icons-material';
import AppCheckbox from '../AppCheckbox';
import AppUtils from '../../AppUtils';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding:'25px'
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


const cblabel = { inputProps: { 'aria-label': 'Checkbox demo' } };
function Roles() {
    const state = useSelector((state) => state);
    const [user_access_levels,setUserAccessLevel] = React.useState(AppUtils.user_access_levels);
    const [user_access_level_arr,setUserAccessLevelArr] = React.useState(['all_access']);
    const [user_roles,setUserRoles] = React.useState([]);
    const [role_name,setRoleName] = React.useState('');
    const [role_id,setRoleId] = React.useState(0);
    const [isRoleLoading,setIsRoleLoading] = React.useState(true);
    const [isProceed,setIsProceed] = React.useState(false);
    const [isSaveRole,setIsSaveRole] = React.useState(false);
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            getRoles();
        }
    },[])

    const handleChangeRole = (value) =>{
        setRoleName(value);
    }
    const handleProceedForNewRole = () =>{
        if(role_name===""){
            return false;
        }
        setIsProceed(true);
    }
    const handleChangeCB = (e,key) =>{
        let ual = [...user_access_level_arr];
        if(e.target.checked===true && ual.indexOf(key)<0){
            ual.push(key);
            setUserAccessLevelArr(ual);
        }else{
            if(key==='all_accesss'){
                setUserAccessLevelArr([]);
            }
            if(ual.indexOf(key)>=0){
                let idx = ual.indexOf(key);
                ual.splice(idx,1);
                setUserAccessLevelArr(ual);
            }
        }
    }
    const AccessLevels = ({ data }) => {
        return (
          <div style={{ paddingLeft: "20px" }}>
            {data.map((parent) => {
              return (
                <div key={parent.label}>
                    <AppCheckbox label={parent.label} cb_key={parent.index+'_'+parent.key} value={parent.key} checked={(user_access_level_arr.indexOf(parent.index+'_'+parent.key)>=0)?true:false}  handleChangeCB={handleChangeCB}/>
                    {(parent.action) &&
                    <div style={{paddingLeft:'20px'}}>
                        {(parent.action.view!==undefined) &&
                            <AppCheckbox label={'View'} cb_key={parent.index+'_'+parent.key+'_view'} view={parent.key+'_view'}  checked={(user_access_level_arr.indexOf(parent.index+'_'+parent.key+'_view')>=0)?true:false} handleChangeCB={handleChangeCB}/>
                        }
                        {(parent.action.add!==undefined) &&
                            <AppCheckbox label={'Add'} cb_key={parent.index+'_'+parent.key+'_add'} value={parent.key+'_add'} checked={(user_access_level_arr.indexOf(parent.index+'_'+parent.key+'_add')>=0)?true:false} handleChangeCB={handleChangeCB}/>
                        } 
                        {(parent.action.edit!==undefined) &&
                            <AppCheckbox label={'Modify'} cb_key={parent.index+'_'+parent.key+'_edit'} value={parent.key+'_edit'}  checked={(user_access_level_arr.indexOf(parent.index+'_'+parent.key+'_edit')>=0)?true:false} handleChangeCB={handleChangeCB}/>
                        }
                        {(parent.action.verify!==undefined) &&
                            <AppCheckbox label={'Verify'} cb_key={parent.index+'_'+parent.key+'_verify'} value={parent.key+'_verify'}  checked={(user_access_level_arr.indexOf(parent.index+'_'+parent.key+'_verify')>=0)?true:false} handleChangeCB={handleChangeCB}/>
                        }
                        {(parent.action.show_password!==undefined) &&
                            <AppCheckbox label={'Show Password'} cb_key={parent.index+'_'+parent.key+'_show_password'} value={parent.key+'_show_password'}  checked={(user_access_level_arr.indexOf(parent.index+'_'+parent.key+'_show_password')>=0)?true:false} handleChangeCB={handleChangeCB}/>
                        }
                        {(parent.action.delete!==undefined) &&
                            <AppCheckbox label={'Delete'} cb_key={parent.index+'_'+parent.key+'_delete'} checked={(user_access_level_arr.indexOf(parent.index+'_'+parent.key+'_delete')>=0)?true:false} value={parent.key+'_delete'} handleChangeCB={handleChangeCB}/>
                        }
                        {(parent.action.print!==undefined) &&
                            <AppCheckbox label={'Print'} cb_key={parent.index+'_'+parent.key+'_print'} checked={(user_access_level_arr.indexOf(parent.index+'_'+parent.key+'_print')>=0)?true:false} value={parent.key+'_print'} handleChangeCB={handleChangeCB}/>
                        }
                    </div>
                    }
                  {/* Base Condition and Rendering recursive component from inside itself */}
                  <div>
                    {parent.childrens && <AccessLevels data={parent.childrens} />}
                  </div>
                </div>
              );
            })}
          </div>
        );
      };
 const handleSaveAccessLevel =() =>{
    setIsSaveRole(true);
    let send_data = {
        role_id : role_id,
        role_name : role_name,
        access_levels : user_access_level_arr,
      };
      APP_HTTP.REQUEST('users/save_user_role',send_data,true,true).then((response) => {
            setIsSaveRole(false);
            getRoles();
            setRoleName('');
            setRoleId(0);
            setUserAccessLevelArr([]);
            setIsProceed(false);
      });
 }
 const getRoles = () =>{
    APP_HTTP.REQUEST('users/get_roles',{},true,true).then((response) => {
        let resp = response.data.response;
        setUserRoles(resp);
        setIsRoleLoading(false);
    });
 }
 const handleEditRole = (idx) =>{
    setIsProceed(false);
    let roles = [...user_roles];
    let upId = roles[idx].role_id;
    let u_acc_lvl = roles[idx].access_level;
    let r_name = roles[idx].role_name;
    setRoleName(r_name);
    setRoleId(upId);
    setUserAccessLevelArr(u_acc_lvl);
    setTimeout(() => {
        setIsProceed(true);
    }, 100);
 }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <h2 style={{margin:'0px'}}>ROLES</h2>
                    <p style={{margin:'0px',fontSize:'14px'}}>Here, We can create user roles which to be allocate to the user</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={4} spacing={2}>
                    <Grid container spacing={2}>
                    {(AppUtils.has_access(state,'18_roles_add') || AppUtils.has_access(state,'18_roles_edit')) &&
                        <>
                        <Grid item xs={8} sm={8} md={8} spacing={2}>
                        <TextField
                            label="Enter New Role"
                            size="small"
                            id="user-role"
                            fullWidth
                            inputProps={{className:"user-role"}}
                            onChange={(e) => handleChangeRole(e.target.value)}
                            value={role_name}
                        />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} spacing={2}>
                            <Button variant="outlined" onClick={handleProceedForNewRole}>Go <DoubleArrow/> </Button>
                        </Grid>
                        </>
                    }
                        <Divider/>
                        {(AppUtils.has_access(state,'18_roles_view')) &&
                            <Grid item xs={12} sm={12} md={12} spacing={2}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                            <StyledTableCell>ROLES</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(user_roles.map((value,index)=>{
                                                return(
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell component="th" scope="row">
                                                            {value.role_name}
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row" sx={{textAlign:'right',cursor:'pointer'}}>
                                                            {(value.role_id>1) &&
                                                                <DoubleArrow onClick={()=>handleEditRole(index)}></DoubleArrow>
                                                            }
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            }))}
                                            {(user_roles.length===0 && isRoleLoading===true) &&
                                            <StyledTableRow>
                                                
                                                <StyledTableCell component="th" scope="row" colSpan={2}>
                                                <Stack spacing={1}>
                                                    <Skeleton variant="rectangular" sx={{width:'100%'}} height={70} />
                                                </Stack>
                                                </StyledTableCell>
                                            
                                            </StyledTableRow>
                                            }
                                            {(user_roles.length===0 && isRoleLoading===false) &&
                                            <StyledTableRow>
                                                
                                                <StyledTableCell component="th" scope="row" colSpan={2} sx={{textAlign:'center'}}>
                                                    No ! Roles Found
                                                </StyledTableCell>
                                            
                                            </StyledTableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={8} spacing={2}>
                    {(isProceed===true) &&
                        <Box sx={{border:'1px solid #d7d7d7'}}>
                            <h2 style={{margin: '0px',textTransform: 'uppercase', background: '#5e86ad',padding: '5px 20px',color: '#fff'}}>MAP ACCESS LEVEL TO {role_name}</h2>
                            <Box sx={{paddingLeft:'20px'}}>
                            <AppCheckbox label={'All Access'} cb_key={'all_access'} checked={(user_access_level_arr.indexOf('all_access')>=0)?true:false} value={'all_access'} handleChangeCB={handleChangeCB}/>
                            </Box>
                            {(user_access_level_arr.indexOf('all_access')<0) &&
                            <>
                                <Divider />
                                <AccessLevels data={user_access_levels}/>
                            </>
                            }
                            <Divider />
                            {(AppUtils.has_access(state,'18_roles_add') || AppUtils.has_access(state,'18_roles_edit')) &&
                            <Box sx={{padding:'15px 20px',textAlign:'right'}}>
                                {(isSaveRole===false) &&
                                    <Button variant="outlined" onClick={handleSaveAccessLevel}>Save Access Level</Button>
                                }
                                {(isSaveRole===true) &&
                                    <LoaderButton label="Saving..."/>
                                }
                            </Box>
                            }
                        </Box>
                    }
                </Grid>
            </Grid>
        </Box>
    </Box>
  );
}
export default React.memo(Roles);