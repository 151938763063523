import { Autocomplete, Avatar, Box, Button, ButtonGroup, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import AppDropDown from '../AppDropDown';
import BasicDatePicker from '../DatePicker';
import AppUtils from '../../AppUtils';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding:'25px'
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

function Transaction(props) {
  const state = useSelector((state) => state);
   const [payment_type,setPaymentType] = React.useState('all');
   const [fromDate,setFromDate] = React.useState(new Date());
   const [toDate,setToDate] = React.useState(new Date());
   const [filterType,setFilterType] = React.useState('today');
   const [page,setPage] = React.useState(0);
   const [transaction_list,setTransactionList] = React.useState([]);
   const [isGenerating,setIsGenerating] = React.useState(false);
   const [isLoadMore,setIsLoadMore] = React.useState(false);
   const [isAllRecordLoaded,setIsAllRecordLoaded] = React.useState(false);
   const handleChangePaymentType = (id,value)=>{
    setPaymentType(value);
   }
   const handleChangeFilterType = (id,value)=>{
    setFilterType(value);
   }
  
  const handleChangeFromDate = (id,date) =>{
    setFromDate(date);
  }
  const handleChangeToDate = (id,date) =>{
    setToDate(date);
  }
  const handleResetList = () =>{
    setPage(0);
    setIsGenerating(false);
    setIsLoadMore(false);
    setTransactionList([]);
    setIsAllRecordLoaded(false);
  }
  const handleGenerateReport = () =>{
    setIsGenerating(true);
    handleLoadReport();
  }
  const handleLoadMore = () =>{
    setIsLoadMore(true);
    handleLoadReport();
  }
  const handleLoadReport = () =>{
    let transList = [...transaction_list];
    let next_page = page+1;
    setPage(next_page);
    let data = {page:next_page,fromDate:fromDate,toDate:toDate,filterType:filterType,transaction_type:payment_type};
     APP_HTTP.REQUEST('reports/transaction',data,true,true,false,true).then((response) => {
      if(next_page===1){
        setIsGenerating(false);
      }else{
        setIsLoadMore(false);
      }
      let trans_list = response.data.response;
      if(trans_list.length>0){
        for (let index = 0; index < trans_list.length; index++) {
          const element = trans_list[index];
          transList.push(element);
        }
        setTransactionList(transList);
      }else{
        setIsAllRecordLoaded(true);
      }
    });
  }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <h2 style={{margin:'0px'}}>TRANSACTION REPORT</h2>
                    <p style={{margin:'0px',fontSize:'14px'}}>Here, we can see the payments transaction report as per applied filter.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={3} spacing={2}>
                    <AppDropDown label="Transaction Type" list_item={[{key:'all',value:'ALL'},{key:'in',value:'PAYMENT IN(+)'},{key:'out',value:'PAYMENT OUT(-)'}]} size="small" handleChangeData={handleChangePaymentType} defaultValue={payment_type}/>
                </Grid>
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <AppDropDown label="Filter Type" list_item={[{key:'today',value:'Today'},{key:'yesterday',value:'Yesterday'},{key:'thisweek',value:'This Week'},{key:'lastweek',value:'Last Week'},{key:'thismonth',value:'This Month'},{key:'custom',value:'Custom'}]} size="small" handleChangeData={handleChangeFilterType} defaultValue={filterType}/>
                </Grid>
                {(filterType==='custom') &&
                <>
                  <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <BasicDatePicker label="Select From Date" size="small" handleChangeData={handleChangeFromDate}/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} spacing={2}>
                    <BasicDatePicker label="Select To Date" size="small" handleChangeData={handleChangeToDate}/>
                  </Grid>
                </>
                }
               
                
                <>
                {(AppUtils.has_access(state,'15_tranaction_view')) &&
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                    {(isGenerating===false && transaction_list.length===0) &&
                      <Button variant='outlined' size='medium' onClick={handleGenerateReport}>GENERATE</Button>
                    }
                    {(isGenerating===false && transaction_list.length>0) &&
                      <Button variant='outlined' size='medium' onClick={handleResetList}>RESET LIST</Button>
                    }
                    {(isGenerating===true) &&
                    <LoaderButton label="Generating..."  variant='outlined' size='medium'/>
                    }
                </Grid>
                }
                </>
                
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                
                {/*<Grid item xs={12} sm={12} md={12} spacing={2} sx={{textAlign:'right'}}>
                    <DownloadAttendence data={{payment_type:payment_type,attendence_ids:attendence_ids,attendence_info:attendence_info,attendence_list:attendence_list}}/>
                  </Grid>
                 */}
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                  
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>PAYMENT NO</StyledTableCell>
                            <StyledTableCell>PAYMENT TYPE</StyledTableCell>
                            <StyledTableCell>CONTACT NAME</StyledTableCell>
                            <StyledTableCell>AMOUNT</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {transaction_list && transaction_list.map((row,index) => (
                            <StyledTableRow>
                              <StyledTableCell component="th" scope="row">
                                {row.payment_sequence}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {row.payment_type}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {row.contact_info.name}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                              ₹{AppUtils.currency_format(row.paid_amount)}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                          {(transaction_list.length===0 && isGenerating===false) &&
                           <StyledTableRow>
                            <StyledTableCell component="th" scope="row" colSpan={4} sx={{textAlign:'center'}}>
                              Please apply fitler to see the report
                            </StyledTableCell>
                          </StyledTableRow>
                          }
                          {(isLoadMore===true) &&
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row" colSpan={4}>
                            <Stack spacing={1}>
                                      <Skeleton variant="rectangular" sx={{width:'100%'}} height={30} />
                                  </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                          }
                           {(isGenerating===true) &&
                            <>
                                
                                {Array.from(Array(5), (e, i) => {
                                    return(
                                      <StyledTableRow>
                                      <StyledTableCell component="th" scope="row" colSpan={4}>
                                      <Stack spacing={1}>
                                                <Skeleton variant="rectangular" sx={{width:'100%'}} height={30} />
                                            </Stack>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                    )
                                    })}
                                
                            </>
                            }
                          {(isAllRecordLoaded===false && transaction_list.length>0) &&
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row" sx={{textAlign:'center'}} colSpan={4}>
                            {(isLoadMore===false) &&
                              <Button variant='outlined' size='small' onClick={handleLoadMore}>Load More</Button>
                            }
                            {(isLoadMore===true) &&
                              <LoaderButton label="Loading..."  variant='outlined' size='small'/>
                            }
                            </StyledTableCell>
                          </StyledTableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                   
                </Grid>
               
            </Grid>
        </Box>
    </Box>
  );
}
export default React.memo(Transaction);