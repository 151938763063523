import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import APP_HTTP from '../../APP_HTTP';
import { Alert, AlertTitle, Avatar, Button, ButtonGroup, Divider, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Skeleton, Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Send } from '@mui/icons-material';
import AppDropDown from '../AppDropDown';


const Notices = (props) => {
    const [notice_to,setNoticeType] = React.useState('all');
    const [message,setMessage] = React.useState('');
    const [notice_list,setNoticeList] = React.useState([]);
    const initialized = React.useRef(false);
    const dispatch = useDispatch();
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handleLoadNotice();
        }
    },[])
    const handleLoadNotice = () =>{
        APP_HTTP.REQUEST('notice/get_notices',{},true,true).then((response) => {
            let resp = response.data;
            let rep_list = resp.response;
            setNoticeList(rep_list);
        });
    }
    const handleSetMessage = (value) =>{
        setMessage(value)
    }
    const handleAddNotice = () =>{
        let not_list = [...notice_list];
        let not_item = {notice_to:notice_to,message:message};
        not_list.push(not_item);
        setNoticeList(not_list);
        APP_HTTP.REQUEST('notice/save_notice',{notice_to:notice_to,message:message},true,true).then((response) => {
            setMessage('');
        });
    }
    const handleEnterPress = (e) =>{
        if(e.which===13){
            handleAddNotice();
        }
    }
    const handleRemoveNotice = (id,index) =>{
        let not_list = [...notice_list];
        not_list.splice(index,1);
        setNoticeList(not_list);
        APP_HTTP.REQUEST('notice/remove_notice',{id:id},true,true).then((response) => {
            
        });
    }
    const handleChangeNoticeType = (id,value) =>{
        setNoticeType(value);
    }
  return (
      <>
          <Box className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            <Grid item xs={12} sm={12} md={12} spacing={2}>
            <AppDropDown label="Notice To" list_item={[{key:'all',value:'To All'},{key:'staff',value:'To Staff'},{key:'parent',value:'To Parent'}]} size="small" handleChangeData={handleChangeNoticeType} defaultValue={notice_to} id='notice-type'/>
            </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={2}>
              <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-reply">Notice</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-reply"
                        type='text'
                        onChange = {(event)=>handleSetMessage(event.target.value)}
                        onKeyDown={(e)=>handleEnterPress(e)}
                        value={message}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="outlined-adornment-reply"
                              onClick={handleAddNotice}
                              edge="end"
                            >
                            <Button variant='outlined'>Create</Button>
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Create"
                      />
                    </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={2}>
            {(notice_list.length>0) &&
              <Stack sx={{ width: '100%' }} spacing={2}>
                {(notice_list.map((value,index)=>{
                    return (
                        <Alert variant='filled' severity="warning" onClose={() => handleRemoveNotice(value.notice_id,index)}>
                            <AlertTitle sx={{textTransform:'capitalize'}}>{value.notice_to}</AlertTitle>
                            {value.message}
                        </Alert>
                    )
                }))}
                </Stack>
                }
              </Grid>
            </Grid>
          
          </Box>
      </>
  );
}
export default Notices;