import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Divider, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';
import LoaderButton from '../LoaderButton';
import { AccountCircleRounded } from '@mui/icons-material';
import AppDropDown from '../AppDropDown';
import APP_HTTP from '../../APP_HTTP';
import AppUtils from '../../AppUtils';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



const SchoolProfile = (props)=> {
    let schoolDataInitialState = {
        school_name:'',
    }
    let [image_url,setImageURL] = React.useState('');
    
    let [isSaveClicked,setIsSaveClicked] = React.useState(false);
    let [schoolData,setSchoolData] = React.useState(schoolDataInitialState);
    const state = useSelector((state) => state);
    const states = state.states;
    let state_list = [];
    for (let index = 0; index < states.length; index++) {
        const state_name = states[index];
        let list_item = {key:state_name,value:state_name};
        state_list.push(list_item);
    }
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            APP_HTTP.REQUEST('school/get_school_profile',{},true,true).then((response) => {
                let resp = response.data.response;
                if(resp){
                    let info = resp.info;
                    setSchoolData(info);
                    setImageURL(info.photo);
                }
            });
        }
       
    },[props])

  const handleSaveInformation = (type) =>{
    setIsSaveClicked(true);
    let upload_file = document.getElementById("upload_file");
    const files = upload_file.files
    const formData = new FormData();
    let photo = files[0];
    formData.append('photo', photo);
    formData.append('data', JSON.stringify(schoolData));
    setSchoolData({});
    APP_HTTP.REQUEST('school/save_school',formData,true,true,true).then((response) => {
        setIsSaveClicked(false);
    });
  }
  const handleUploadFileClick = () =>{
    if(document.getElementById("upload_file")){
        document.getElementById("upload_file").click();   
    }
  }
  const handleUploadFile = (e) =>{
    const files = e.target.files;
    if(files[0]){
        var url = URL.createObjectURL(files[0]);
        setImageURL(url);
    }
  }
  const handleChangeData = (id,value) =>{
    let stuData = schoolData;
    if(id.target && id.target.id){
        let value = id.target.value;
        let key = id.target.id;
        stuData[key] = value;
    }else{
        stuData[id] = value;
    }
    setSchoolData(stuData);
  }
  
  return (
    <div id="create-school">
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Grid container spacing={2} className="mrtop-10">  
                    <Grid item xs={12} sm={12} md={12} sx={{color: '#3c3c3c',padding: '5px', borderBottom:'1px solid #d7d7d7'}}>
                        <Grid container spacing={2} className="mrtop-10"> 
                            <Grid item xs={6}> 
                            
                            <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>School Profile</Typography>
                            <Typography variant='span' sx={{fontSize:'14px',color:'gray'}}>Please fill the school information in below field</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign:'right'}}> 
                                <Typography variant='span' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className="mrtop-15" style={{textAlign:'center'}}>
                        <input type='file' id="upload_file" accept="image/png,image/jpg, image/jpeg" style={{display:'none'}} onChange={handleUploadFile}/>
                        <Box sx={{height:'150px',width:'150px',border:'5px solid #d7d7d7',margin:'0 auto',borderRadius:'50%',cursor:'pointer'}} onClick={handleUploadFileClick}>
                            {(image_url!=='' && image_url!==undefined) &&
                                <img src={image_url} alt="Image" height={'100%'} width={'100%'} style={{borderRadius:'50%'}}/>
                            }
                            {(image_url==='' || image_url===undefined) &&
                                <AccountCircleRounded size="large" sx={{height:'100%',width:'100%',color:'#d7d7d7'}}/>
                            }
                        </Box>
                    </Grid>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>School Information</h3>
                        <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="School Name"
                                defaultValue={(schoolData && schoolData.school_name)?schoolData && schoolData.school_name:''}
                                key={(schoolData && schoolData.school_name)?schoolData && schoolData.school_name:''}
                                size="small"
                                id="school_name"
                                fullWidth
                                inputProps={{className:"school-data"}}
                                onChange={(e)=>handleChangeData('school_name',e.target.value)}
                                />
                        </Grid>
                       
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Mobile Number"
                                onChange={(e)=>handleChangeData('mobile_number',e.target.value)}
                                size="small"
                                id="mobile_number"
                                fullWidth
                                inputProps={{className:"school-data"}}
                                defaultValue={(schoolData && schoolData.mobile_number)?schoolData && schoolData.mobile_number:''}
                                key={(schoolData && schoolData.mobile_number)?schoolData && schoolData.mobile_number:''}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Alternate Mobile Number"
                                onChange={(e)=>handleChangeData('alt_mobile_number',e.target.value)}
                                size="small"
                                id="alt_mobile_number"
                                fullWidth
                                inputProps={{className:"school-data"}}
                                defaultValue={(schoolData && schoolData.alt_mobile_number)?schoolData && schoolData.alt_mobile_number:''}
                                key={(schoolData && schoolData.alt_mobile_number)?schoolData && schoolData.alt_mobile_number:''}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Email ID"
                                onChange={(e)=>handleChangeData('email',e.target.value)}
                                size="small"
                                id="email"
                                fullWidth
                                inputProps={{className:"school-data"}}
                                defaultValue={(schoolData && schoolData.email)?schoolData && schoolData.email:''}
                                key={(schoolData && schoolData.email)?schoolData && schoolData.email:''}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="City"
                                onChange={(e)=>handleChangeData('city',e.target.value)}
                                size="small"
                                id="city"
                                fullWidth
                                inputProps={{className:"school-data"}}
                                defaultValue={(schoolData && schoolData.city)?schoolData && schoolData.city:''}
                                key={(schoolData && schoolData.city)?schoolData && schoolData.city:''}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <AppDropDown label="State" id="state" defaultValue={(schoolData && schoolData.state)?schoolData && schoolData.state:''} list_item={state_list} size="small" className="school-data" handleChangeData={handleChangeData}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Pincode"
                                onChange={(e)=>handleChangeData('pincode',e.target.value)}
                                size="small"
                                id="pincode"
                                fullWidth
                                inputProps={{className:"school-data"}}
                                defaultValue={(schoolData && schoolData.pincode)?schoolData && schoolData.pincode:''}
                                key={(schoolData && schoolData.pincode)?schoolData && schoolData.pincode:''}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Locality"
                                onChange={(e)=>handleChangeData('locality',e.target.value)}
                                size="small"
                                id="locality"
                                fullWidth
                                inputProps={{className:"school-data"}}
                                defaultValue={(schoolData && schoolData.locality)?schoolData && schoolData.locality:''}
                                key={(schoolData && schoolData.locality)?schoolData && schoolData.locality:''}
                                />
                        </Grid>
                </Grid>
            </Grid>
        </Grid>
        {(AppUtils.has_access(state,'31_profile_edit')) &&
        <Grid container xs={12} sm={12} md={12} spacing={2} className="mrtop-30 footer-btn-blk">
            <Grid xs={6}></Grid>
            <Grid xs={6} sx={{textAlign:'right'}}>
            {(isSaveClicked===false) &&
                <Button variant="contained" onClick={() => handleSaveInformation('save')}>Save Information</Button>
            }
            {(isSaveClicked===true) &&
                <LoaderButton label="Save Information"/>
            }
            </Grid>
        </Grid>
        }
        </Box>
    </div>
  );
}
export default SchoolProfile;