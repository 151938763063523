import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function AppDropDown(props) {
  const [defaultValue, setDropdownValue] = React.useState(props.defaultValue);
  const handleChange = (event) => {
    let thisvalue = event.target.value;
    let id = props.id;
    setDropdownValue(thisvalue);
    if(props.handleChangeData){
      props.handleChangeData(id,thisvalue);
    }
  };

  return (
    
      <FormControl fullWidth>
        <InputLabel id={props.id+"-select-label"}>{props.label}</InputLabel>
        <Select
          labelId={props.id+"-select-label"}
          id={props.id+"-select"}
          value={defaultValue}
          label={props.label}
          onChange={handleChange}
          size={props.size}
          inputProps={{className:props.className}}
        >
            {(props.list_item.map((value)=>{
                return (
                    <MenuItem value={value.key}>{value.value}</MenuItem>
                )
            }))}
        </Select>
      </FormControl>
    
  );
}