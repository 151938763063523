import * as React from 'react';
const date = new Date();
let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();
let currentDate = `${day}-${month}-${year}`;
const PrintFooter = (props)=> {
   
  return (
    <tfoot>
        <tr>
            <td style={{borderRight:'none',paddingTop:'56px',textAlign:'center'}}>
              <h4 style={{fontWeight:'bold',fontSize:'15px'}}>
                <i>{currentDate}</i>
              </h4>
              <h4 style={{fontWeight:'bold',fontSize:'15px'}}>Date</h4>
            </td>
            <td colSpan={(props.colSpan)?props.colSpan-1:''} style={{textAlign:'right',borderLeft:'none',paddingTop:'75px'}}>
                <h4 style={{fontWeight:'bold',fontSize:'15px'}}>Authorized Signature</h4>
            </td>
        </tr>
    </tfoot>
  );
}
export default PrintFooter;