import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const PrintScheduledExam = (props)=> {
    let [scheduledExamData,setScheduledExamData] = React.useState([]);
    
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            if(props.scheduled_exam_data){
                let scheduled_exam_data = props.scheduled_exam_data;
                
                setScheduledExamData(scheduled_exam_data);
                setTimeout(() => {
                    handlePrintScheduledExam();
                }, 100);
            }
        }
       
    },[props])
   const handlePrintScheduledExam = ()=>{
    if(document.getElementById("scheduledExamPrintBlock")){
        var divContents = document.getElementById("scheduledExamPrintBlock").innerHTML; 

      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
     
          a.document.write('<style type="text/css"><style type="text/css">@page{size:"A4";margin:5cm}table{font-size:14px;font-weight:bold;width:100%;margin-bottom:1rem;color:#212529;border-collapse:collapse}table td,table th{padding:.75rem;vertical-align:top;border:1px solid #dee2e6}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
           a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
           a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');  
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="scheduledExamPrintBlock" style={{display:'none'}}>
        {(scheduledExamData.school_info) &&
            <table>
                <PrintHeader header_data={scheduledExamData.school_info} colSpan={3}/>
                <tbody>
                    <tr>
                        <th style={{textAlign:'left',backgroundColor:'rgb(251 251 251)'}} colSpan={3}>
                            <h2 style={{margin:'0px'}}>
                                {scheduledExamData.exam_type_name} for class {scheduledExamData.class_name}
                            </h2>
                        </th>
                    </tr>
                    <tr>
                        <th style={{textAlign:'left',backgroundColor:'rgb(251 251 251)'}} colSpan={3}><h2 style={{margin:'0px'}}>Exam Time Table</h2></th>
                    </tr>            
                    <tr>
                        
                        <th style={{textAlign:'left'}}>SUBJECT</th>
                        <th style={{textAlign:'left'}}>DATE & TIME</th>
                    </tr>            
                                {scheduledExamData.sorted_schedules && scheduledExamData.sorted_schedules.map((value,index)=>{
                                return (
                                    <>
                                    {(value.active===true) &&
                                    <tr>
                                        
                                        <td style={{textAlign:'left'}}>
                                        <span>
                                            {value.name}
                                        </span>
                                        </td>
                                        <td style={{textAlign:'left'}}>
                                        {value.exam_date_display}
                                        </td>
                                    </tr>
                                }
                                    </>
                                )
                            })}
                                
                                
                            
                </tbody>
                <PrintFooter colSpan={5}/>
            </table>
        }
    </div>
  );
}
export default PrintScheduledExam;