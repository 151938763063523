import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import AppUtils from '../../AppUtils';
import PrintHeader from '../PrintHeader';
import PrintFooter from '../PrintFooter';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const PrintAllocatedRoom = (props)=> {
    let [allocatedRoomData,setAllocatedRoomData] = React.useState([]);
    
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            if(props.data){
                let data = props.data;
                setAllocatedRoomData(data);
                setTimeout(() => {
                    handleAllocatedHallPrint();
                }, 200);
            }
        }
       
    },[props])
   const handleAllocatedHallPrint = ()=>{
    if(document.getElementById("allocatedRoomPrintBlock")){
        var divContents = document.getElementById("allocatedRoomPrintBlock").innerHTML; 

      try{
        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.open(); 
        a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
     
          a.document.write('<style type="text/css"><style type="text/css">@page{size:"A4";margin:5cm}table{font-size:14px;font-weight:bold;width:100%;margin-bottom:1rem;color:#212529;border-collapse:collapse}table td,table th{padding:.75rem;vertical-align:top;border:1px solid #dee2e6}table th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;} td{line-height: 0.9 !important;}</style></style></style>'); 
        
           a.document.write('</head>'); 
    
           a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
           a.document.write('<script>function afterPrint() {if(localStorage.getItem("save_print_new")!==null){} window.close();}</script>');  
          
      
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        
      }catch{}
    }
   }
  return (
    <div id="allocatedRoomPrintBlock" style={{display:'none'}}>
        {(allocatedRoomData.school_info) &&
             <table>
                <PrintHeader header_data={allocatedRoomData.school_info} colSpan={3}/>      
                <tbody>
                {allocatedRoomData && allocatedRoomData.scheduled_data && allocatedRoomData.scheduled_data.map((value,index)=>{
                    return (
                        <>
                        <tr>
                        <td colSpan={3}>
                            <h2 style={{margin:'0px'}}>
                            {value.date}
                            </h2>
                        </td>
                        </tr>
                        {value.class_rooms && value.class_rooms.map((room,ridx)=>{
                            return (
                            <>
                                <tr >
                                <td style={{paddingLeft:'50px'}}>
                                    <h3 style={{margin:'0px',fontSize:'18'}}>
                                    {room.class_room_info.name}
                                    </h3>
                                </td>
                                <td colSpan={2}>
                                {room.selected_invigilator.toString()}
                                    
                                </td>
                                </tr>
                                {room.class_list && room.class_list.map((cls,clidx)=>{
                                return (
                                    <>
                                    {(cls.roll_from!=="" && cls.roll_to!=="") &&
                                        <tr>
                                            <td style={{paddingLeft:'100px'}}>
                                            <h4 style={{margin:'0px',fontSize:'16'}}>
                                                Class {cls.name}
                                            </h4>
                                            </td>
                                            <td colSpan={2}>
                                            {cls.roll_from} To {cls.roll_to}
                                            </td>
                                        </tr>
                                    }
                                    </>
                                    )
                                })}
                            </>
                            )
                        })}
                        </>
                    )
                })}
                
                </tbody>
                <PrintFooter colSpan={5}/>
            </table>
        }
    </div>
  );
}
export default PrintAllocatedRoom;