import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import { Avatar, Button, Divider, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AppPagination from '../AppPagination';
import { AddCircleOutline, ArrowRightAlt, Delete, Edit, PlusOneRounded } from '@mui/icons-material';
import DeleteConfirm from '../DeleteConfirm';
import { handleConfirmDeleteStudent, handleSetStudentsList, handleToggleEditStudent,handleSetStudentInfo, handleToggleViewStudent } from './Action';
import CreateStudent from './CreateStudent';
import { handleChangeMenu } from '../../actions/Action';
import AppUtils from '../../AppUtils';
import AddOpeningBalance from './AddOpeningBalance';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StudentList = () => {
  const [delete_data,setDeleteData] = React.useState({id:0,endpoint:''});
  
  const [student_data,setStudentData] = React.useState([]);
  const state = useSelector((state) => state);
  const is_delete_item = state.is_delete_item;
  const student_list = state.students_list;
  const [number_of_page,setNumberOfPage] = React.useState(0);
  const [total_records,setTotalRecords] = React.useState(0);
  const [is_loading_list,setIsLoadingList] = React.useState(true);
  const [is_add_ob,setIsAddOB] = React.useState(false);
  
  
  const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            let current_path = window.location.pathname;
            let numberPattern = /\d+/g;
            let get_page = current_path.match( numberPattern );
            if(get_page===null){
              handleLoadContactList(1)
            }
        }
    },[])
    const handleLoadContactList = (page) =>{
      setIsLoadingList(true);
      dispatch(handleSetStudentsList([]));
      APP_HTTP.REQUEST('student/get_students',{page:page,total_records:total_records},true,true).then((response) => {
          let resp = response.data;
          let stu_list = resp.response;
          
          let tot_record = resp.total_record;
          let no_of_page = resp.number_of_page;
          setTotalRecords(tot_record);
          setNumberOfPage(no_of_page);
        
          dispatch(handleSetStudentsList(stu_list));
          setIsLoadingList(false);
          setStudentData(resp);
      });
    }
    const handleNavigatePage = (page) =>{
      handleLoadContactList(page);
    }

    const handleDeleteItem = (id)=> {
      let studentValue = student_list[id];
      let student_id = studentValue.student_id;
      
      let delData = {id:student_id,index:id,target:'student'};
      setDeleteData(delData);
      dispatch(handleConfirmDeleteStudent(true));
  }
  const handleEditItem = (id)=>{
    let student_info = student_list[id];
    let student_id = student_info.student_id;
    dispatch(handleChangeMenu('student','edit/'+student_id,'Update Student',false));
    dispatch(handleToggleEditStudent(true));
    dispatch(handleSetStudentInfo(student_info));
    
  }
  const handleViewItem = (id)=>{
    
    if(AppUtils.has_access(state,'2_students_view')===false){
      return false;
    }
    let student_info = student_list[id];
    let student_id = student_info.student_id;
    dispatch(handleChangeMenu('student','view/'+student_id,'Student Profile',false));
    dispatch(handleToggleViewStudent(true));
    dispatch(handleSetStudentInfo(student_info));
    
  }
  const handleSearch = (e)=>{
    let thisvalue = e.currentTarget.value;
    if(thisvalue!==''){
      if(thisvalue.length<2){
        return false; 
      }
      setIsLoadingList(true);
      dispatch(handleSetStudentsList([]));
      APP_HTTP.REQUEST('student/search_student',{search:thisvalue},true,true,false,true).then((response) => {
            if(response.data){
              let resp = response.data;
              let stf_list = resp.response;
              let tot_record = resp.total_record;
              let no_of_page = resp.number_of_page;
              setTotalRecords(tot_record);
              setNumberOfPage(no_of_page);
              
              dispatch(handleSetStudentsList(stf_list));
              setIsLoadingList(false);
          }
      });
    }else{
      let resp = student_data;
      let stf_list = resp.response;
      if(stf_list){
        let tot_record = resp.total_record;
        let no_of_page = resp.number_of_page;
        setTotalRecords(tot_record);
        setNumberOfPage(no_of_page);
        
        dispatch(handleSetStudentsList(stf_list));
        setIsLoadingList(false);
      }
    }
  }

  const handleAddOpeningBalance = ()=>{
    setIsAddOB(true)
  }
  const handleCloseOpeningBalance = ()=>{
    setIsAddOB(false)
  }
  return (
      <>
          <Box className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
            <Grid item xs={12} sm={12} md={3} spacing={2}>
              <TextField
                label="Search Student"
                size="small"
                fullWidth
                onChange={handleSearch}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={9} spacing={2} sx={{textAlign:'right'}}>
              <Button variant='outlined' size='small' onClick={handleAddOpeningBalance}>Add Opening Balance</Button>
              </Grid>
             
              <Divider />
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>STUDENT ID | ROLL NO</StyledTableCell>
                        <StyledTableCell>NAME</StyledTableCell>
                        <StyledTableCell>CLASS</StyledTableCell>
                        <StyledTableCell>SECTION</StyledTableCell>
                        <StyledTableCell>OPENING BALANCE</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {student_list.map((row,index) => (
                        <StyledTableRow key={row.student_sequence}>
                          <StyledTableCell  onClick={() =>handleViewItem(index)} sx={{cursor:'pointer'}}>
                          <Avatar
                            alt={row.student_name}
                            src={row.photo}
                          />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row"  onClick={() =>handleViewItem(index)} sx={{cursor:'pointer'}}>
                            {row.student_sequence} | {row.roll_number}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.student_name}
                          </StyledTableCell>
                          <StyledTableCell>{row.class}</StyledTableCell>
                          <StyledTableCell>{row.section}</StyledTableCell>
                          <StyledTableCell>₹{AppUtils.currency_format(row.opening_balance)}</StyledTableCell>
                          <StyledTableCell>
                          {(AppUtils.has_access(state,'2_students_delete')) &&
                            <Delete fontSize='small' onClick={() =>handleDeleteItem(index)} sx={{cursor:'pointer'}}/>
                          }
                            {(AppUtils.has_access(state,'2_students_edit')) &&
                              <Edit  fontSize='small' onClick={() =>handleEditItem(index)} sx={{cursor:'pointer'}}/>
                            }
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      {(is_loading_list===true) &&
                      <StyledTableCell component="th" scope="row" colSpan={6} sx={{textAlign:'center'}}>
                      Please wait while loading the list...
                      </StyledTableCell>
                      }   
                      {(is_loading_list===false && student_list.length===0) &&
                      <StyledTableCell component="th" scope="row" colSpan={6} sx={{textAlign:'center'}}>
                        Oops ! No Record(s) Found.
                      </StyledTableCell>
                      }   
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <AppPagination number_of_page={number_of_page} handleNavigatePage={handleNavigatePage} url={'/students/page'}/>
              </Grid>
            </Grid>
            {(is_delete_item) &&
              <DeleteConfirm delete_data={delete_data} title="Delete Confirmation" />
            }
            {(is_add_ob===true) &&
              <AddOpeningBalance handleCloseOB={handleCloseOpeningBalance}/>
            }
          </Box>
      </>
  );
}

export default StudentList;