import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { handleOpenAddClass,handleSaveClassToList,handleUpdateClass } from './Action';
import APP_HTTP from '../../../APP_HTTP';
let initialState = {id:0,name:'',is_edit:false};
const CreateClass = (props)=> {
  const [classItem,setClassItem] = React.useState(initialState);
  const [className,setClassName] = React.useState('');
  const state = useSelector((state) => state);
  const is_open_add_class = state.is_open_add_class;
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(handleOpenAddClass(false));
  };
  React.useEffect(() => {
      let classValue = props.classValue;
      setClassItem(classValue);
      setClassName(classValue.name);
  },[props]);
  const handleSaveClass = () =>{
    let update_idx = 0;
    let update_id = 0;
    if(props.classValue && props.classValue.is_edit && props.classValue.update_id>=0){
      update_idx = props.classValue.update_id;
      update_id = props.classValue.id;
      dispatch(handleUpdateClass(update_idx,className));
    }
    APP_HTTP.REQUEST('settings/create_class',{id:update_id,name:className},true,true).then((response) => {
      let resp = response.data;
      let id = resp.id;
      if(update_id===0){
        classItem.id = parseInt(id);
        dispatch(handleSaveClassToList(classItem));
      }
    });
    handleClose();
  }
  const handleSetClassName = (e) =>{
    let thisvalue = e.currentTarget.value;
    let clsitem = classItem;
    clsitem.name = thisvalue;
    setClassItem(clsitem);
    setClassName(thisvalue);
  }
  return (
      <Dialog open={is_open_add_class} onClose={handleClose}>
        <DialogTitle>Create Class</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="class_name"
            label="Enter Class Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleSetClassName}
            value={className}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='small'>Close</Button>
          <Button onClick={handleSaveClass} variant='contained' color='success' size='small'>Save</Button>
        </DialogActions>
      </Dialog>
    
  );
}
export default CreateClass;