import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { handleChangeMenu } from "../actions/Action";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SchoolIcon from '@mui/icons-material/School';
import Copyright from './Copyright';
import APP_HTTP from '../APP_HTTP';
import LoaderButton from './LoaderButton';
const theme = createTheme();

export default function Signup() {
const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [is_submit_click, setSubmit] = React.useState(false);
  const handleSubmit = (event) => {
    setSubmit(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let send_data = {
      school_name: data.get('school_name'),
      email: data.get('email'),
      username: data.get('username'),
      password: data.get('password'),
    };
    APP_HTTP.REQUEST('school/create_school',send_data).then((response) => {
      if(response.data.success===1){
        let data = response.data.data;
        localStorage.setItem('MnzYs4pVGL',JSON.stringify(data));
        setSubmit(false);
        window.location.href = '/';
      }
    });
  };
  const handleSelectMenu = (label,menu,sub_menu='',is_enable_add_btn=false) => {
    dispatch(handleChangeMenu(menu,sub_menu,label,is_enable_add_btn));
    /* if(menu==='logout'){
      window.location.href = '/login';
    }*/
  }
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <SchoolIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register School
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="school_name"
              label="School Name"
              name="school_name"
              autoComplete="school_name"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {(!is_submit_click) &&
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Register
              </Button>
            }
            {(is_submit_click) &&
               <LoaderButton
               fullWidth
               variant="contained"
               sx={{ mt: 3, mb: 2 }}/>
            }
            <Grid container>
                {/* <Grid item xs>
                <Link href="#" variant="body2">
                Forgot password?
                </Link>
                </Grid> */}
                <Grid item>
                <Link onClick={() => handleSelectMenu('Login','login','',false)} variant="body2" sx={{cursor:'pointer'}}>
                    Already have an account? Log In
                </Link>
                </Grid>
            </Grid>
            </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}