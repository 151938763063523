import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import APP_HTTP from '../../APP_HTTP';
import { Avatar, Button, ButtonGroup, Divider, Skeleton, Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Reply } from '@mui/icons-material';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e86ad',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ReportedStaffs = (props) => {
    const initialized = React.useRef(false);
    
    const [reported_list,setReportedList] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(false);
    const [isLoadMore,setIsLoadMore] = React.useState(false);
    const [isEmpty,setIsEmpty] = React.useState(false);
    const [list_page,setPage] = React.useState(1);
    const state = useSelector((state) => state);
    const main_menu = state.main_menu;
    
    const dispatch = useDispatch();
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            handLoadReported(list_page);
        }
    },[])
    const handLoadReported = (page) =>{
      let inb_list = [...reported_list];
      
      APP_HTTP.REQUEST('reported/get_reported_staffs',{page:page},true,true).then((response) => {
          let resp = response.data;
          let rep_list = resp.response;
          if(rep_list.length===0){
            setIsEmpty(true);
          }
          for (let index = 0; index < rep_list.length; index++) {
            const element = rep_list[index];
            inb_list.push(element);
          }
          setReportedList(inb_list);
          setIsLoadMore(false);
      });
    }
   
    const handleLoadMore = () =>{
      setIsLoadMore(true);
      let npage = list_page + 1;
      handLoadReported(npage);
      setPage(npage);
    }
    const handleResolve = (id,index) =>{
        let inb_list = [...reported_list];
        inb_list[index].status = 0;
        setReportedList(inb_list);
        APP_HTTP.REQUEST('reported/resolve_concern',{id:id,type:'staff'},true,true).then((response) => {
            let resp = response.data;
        });
    }
  return (
      <>
          <Box className="MainContainer">
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
          
              <Divider />
              <Grid item xs={12} sm={12} md={12} spacing={2}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                 
                    <TableBody>
                        {(reported_list.map((value,index)=>{
                          return(
                            <StyledTableRow>
                              <StyledTableCell>
                                
                                <Typography  sx={{fontWeight: 600,color: 'gray',fontSize:'14px'}}>
                                  Reported From : {value.reported_from.father_name} | {value.reported_from.student_sequence} | {value.reported_from.student_name} |  {value.reported_from.roll_number} {value.reported_from.class}({value.reported_from.section})
                                </Typography>
                                <Typography  sx={{fontWeight: 600,color: 'gray',fontSize:'14px'}}>
                                  Reported To : {value.contact_info.staff_sequence} | {value.contact_info.staff_name}
                                </Typography>
                                <Typography  sx={{fontFamily:'cursive',color: '#333'}}>{value.message}</Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                {(parseInt(value.status)===1) &&
                                <Button size="small" variant='contained' color="success" onClick={()=>handleResolve(value.reported_staff_id,index)}>Resolve</Button>
                                }
                                {(parseInt(value.status)===0) &&
                                    <Typography sx={{color:'green'}}>Resolved</Typography>
                                }
                              </StyledTableCell>
                            </StyledTableRow>
                          )
                        }))}
                       
                        {(reported_list.length===0 && isLoading===false) &&
                        <StyledTableRow>
                          <StyledTableCell colSpan={2} sx={{textAlign:'center'}}><Typography>Reported is Empty</Typography></StyledTableCell>
                        </StyledTableRow>
                        }
                        {(reported_list.length===0 && isLoading===true) &&
                        <StyledTableRow>
                          <StyledTableCell colSpan={2} sx={{textAlign:'center'}}><Typography>Loading...</Typography></StyledTableCell>
                        </StyledTableRow>
                        }
                        {(isLoadMore===true) &&
                        <StyledTableRow>
                          <StyledTableCell colSpan={2} sx={{textAlign:'center'}}>
                            <Stack spacing={1}>
                              <Skeleton variant="rectangular" sx={{width:'100%'}} height={50} />
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                        }
                        {(reported_list.length>0 && isLoading===false && isLoadMore==false && isEmpty===false) &&
                        <StyledTableRow>
                          <StyledTableCell colSpan={2} sx={{textAlign:'center'}}><Button variant='outlined' size='small' onClick={handleLoadMore}>Load More</Button></StyledTableCell>
                        </StyledTableRow>
                        }
                        
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          
          </Box>
      </>
  );
}

export default ReportedStaffs;