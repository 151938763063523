import { Autocomplete, Avatar, Box, Button, ButtonGroup, Divider, Grid, ListSubheader, Table, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import LoaderButton from '../LoaderButton';
import APP_HTTP from '../../APP_HTTP';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Classes from '../settings/classes/Classes';
import Paper from '@mui/material/Paper';
import { deepOrange, deepPurple } from '@mui/material/colors';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TransportDropdown from './TransportDropdown';
import Modal from '@mui/material/Modal';
import { CheckCircle } from '@mui/icons-material';
import AppUtils from '../../AppUtils';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding:'25px'
  };
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
     
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
let classRoomData = [];
function AllocateTransport(props) {
    const state = useSelector((state) => state);
   let [selectedTransport,setSelectedTransport] = React.useState('');
   let [transportData,setTransportData] = React.useState([]);
   let [is_open_allocation,setIsOpenAllocation] = React.useState(false);
   let [is_selected_class,setIsClassSelected] = React.useState(false);
   let [allocationSeatInfo,setAllocationSeatInfo] = React.useState({});
   let [studentsList,setStudentList] = React.useState([]);
   let [studentInfo,setStudentInfo] = React.useState('');
   
    const handleChangeTransport = (id,value) =>{
        setSelectedTransport(value);
    }
    const handlePreviewTransportUI = () =>{
        APP_HTTP.REQUEST('transport/get_allocated_seat_information',{transport_id:selectedTransport},true,true,false,true).then((response) => {
            setTransportData(response.data.response);
      });
    }
    const handleClickOnSeatNo = (seat_type,parent_index,child_index,seat_no) =>{
        if(AppUtils.has_access(state,'12_allocate_transport_add')===false){
            return false;
        }
        let seat_info = {
            seat_type : seat_type,
            parent_index : parent_index,
            child_index : child_index,
            seat_no : seat_no,
        };
        
        setAllocationSeatInfo(seat_info);
        setIsOpenAllocation(true);
    }
    const handleCloseAllocation = () => {
        setIsOpenAllocation(false);
        setIsClassSelected(false);
    };
    const handleAllocateSeat = () => {
        let seatInfo = allocationSeatInfo;
        seatInfo.transport_id = selectedTransport;
        let parent_index = seatInfo.parent_index;
        let child_index = seatInfo.child_index;
        let trData = transportData;
        let seaterInfo = seatInfo.seater_info;
        
        if(seatInfo.seat_type==='m'){
            trData.seat_plan.seat_m_plan[parent_index][child_index].info = seaterInfo;
        }else if(seatInfo.seat_type==='n'){
            trData.seat_plan.seat_n_plan[parent_index][child_index].info = seaterInfo;
        }
        setTransportData(trData);
        setStudentInfo('');
        APP_HTTP.REQUEST('transport/allocate_transport',{seatInfo},true,true,false,true).then((response) => {
            
        });
        setIsOpenAllocation(false);
    };
    const handleSelectClass = (id,value) =>{
        if(value>0){
            setIsClassSelected(true);
            APP_HTTP.REQUEST('student/search_student',{search:value,type:'autocomplete',class:value},true,true,false,true).then((response) => {
                let resp = response.data;
                let search_list = resp.response;
                setStudentList(search_list);
            });
        }else{
            setIsClassSelected(false);
        }
      }
      const handleChangeInputValue = (value) =>{
    
      }
      const handleChangeValue = (value) =>{
        let allotInfo = allocationSeatInfo;
        allotInfo.seater_info = value;
        setAllocationSeatInfo(allotInfo);
        setStudentInfo(value);
      }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Box>
            <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <h2 style={{margin:'0px'}}>ALLOCATION OF TRANSPORT</h2>
                    <p style={{margin:'0px',fontSize:'14px'}}>Allocate transport to the students.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={3} spacing={2}>
                    <TransportDropdown handleChangeData={handleChangeTransport}/>
                </Grid>
                {(AppUtils.has_access(state,'12_allocate_transport_view') || AppUtils.has_access(state,'12_allocate_transport_add')) &&
                <Grid item xs={12} sm={12} md={9} spacing={2}>
                    {(selectedTransport!=='') &&
                        <Button onClick={handlePreviewTransportUI} variant='outlined' size='medium'>Go</Button>
                    }
                </Grid>
                }
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Divider />
                </Grid>
                {(transportData && transportData.transport_name) &&
                    <Grid item xs={12} sm={12} md={12} spacing={2} sx={{textAlign:'center'}}>
                        {(transportData.transport_number) &&
                            <div style={{textAlign: 'center',
                                border: '1px solid #d7d7d7',
                                width: '355px',
                                padding: '15px',
                                margin: '0 auto',
                                borderTopLeftRadius: '15px',
                                borderTopRightRadius: '15px'}}>
                                <h4 style={{ backgroundColor: 'rgb(255, 87, 34)',
        width: '30px',
        height: '30px',
        paddingTop: '4px',
        borderRadius: '50%',
        color: 'white',margin:'0 auto' }}>{transportData.transport_number}</h4>
                                <h4 style={{margin:'0px'}}>
                                    {transportData.transport_name}
                                </h4>
                                <h4 style={{margin:'0px'}}>
                                    {transportData.vehicle_number}
                                </h4>
                                <hr />
                                <table style={{width:'100%'}}>
                                    <tr>
                                        <td style={{width:'50%'}} colSpan={(transportData.seat_plan && transportData.seat_plan.seat_n_plan && transportData.seat_plan.seat_n_plan.length>0)?2:1}>
                                        </td>
                                        <td style={{width:'50%',textAlign:'right'}}>
                                            <span style={{margin:'0px',padding:'5px',border:'1px solid #d7d7d7'}}>
                                                
                                                    {transportData.driver}
                                                
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'50%'}} colSpan={(transportData.seat_plan && transportData.seat_plan.seat_n_plan && transportData.seat_plan.seat_n_plan.length>0)?3:2}>
                                            <hr />
                                        </td>
                                    </tr>
                                    <tr>
                                    <td style={{width:'50%'}} colSpan={(transportData.seat_plan && transportData.seat_plan.seat_n_plan && transportData.seat_plan.seat_n_plan.length===0)?3:1}>
                                        M
                                    </td>
                                    {(transportData.seat_plan && transportData.seat_plan.seat_n_plan && transportData.seat_plan.seat_n_plan.length>0) &&
                                        <>
                                            <td></td>
                                            <td style={{width:'50%'}}>
                                                N
                                            </td>
                                        </>
                                    }
                                    </tr>
                                    <tr>
                                        <td  colSpan={(transportData.seat_plan && transportData.seat_plan.seat_n_plan && transportData.seat_plan.seat_n_plan.length===0)?3:1}>
                                            <table style={{width:'100%'}}>
                                                {(transportData.seat_plan.seat_m_plan.map((value,index)=>{
                                                    return(
                                                        <tr>
                                                            {(value.map((sv,idx)=>{
                                                                return(
                                                                    <td style={{width:'60px',cursor:'pointer'}} onClick={()=>handleClickOnSeatNo('m',index,idx,sv.seat_no)}>
                                                                        <p style={{border:'1px solid #d7d7d7',padding:'5px',height:'60px'}}>
                                                                            {(sv.info && sv.info.id==undefined) &&
                                                                                <>
                                                                                <span style={{background: 'green',padding: '3px',color: 'white'}}>
                                                                                    {sv.seat_no}
                                                                                </span>
                                                                                <p style={{    margin: '0px',
    fontSize: '9px',
    display: 'inline-block',
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',color:'blue'}}>Click here</p>
                                                                                </>
                                                                            }
                                                                            {(sv.info && sv.info.id) &&
                                                                                <>
                                                                                    
                                                                                    <span  style={{background: '#d9d9d9',padding: '3px',color: 'white'}}>
                                                                                        {sv.seat_no}
                                                                                    </span>
                                                                                    <p style={{    margin: '0px',
    fontSize: '9px',
    display: 'inline-block',
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'}}>{sv.info.name}</p>
                                                                                    <p style={{margin: '-9px 0px 0px 0px',
    fontSize: '9px'}}>{sv.info.label2}</p>
                                                                                </>
                                                                            }
                                                                        </p>
                                                                    </td>
                                                                )
                                                            }))}
                                                        </tr>
                                                    )
                                                }))}
                                            </table>
                                        </td>
                                        {(transportData.seat_plan && transportData.seat_plan.seat_n_plan && transportData.seat_plan.seat_n_plan.length>0) &&
                                        <>
                                        
                                            <td style={{borderRight:'1px solid #d7d7d7'}}></td>
                                            <td>
                                                <table style={{width:'100%'}}>
                                                    {(transportData.seat_plan.seat_n_plan.map((value,index)=>{
                                                        return(
                                                            <tr>
                                                                {(value.map((sv,idx)=>{
                                                                    return(
                                                                        <>
                                                                        {(sv.seat_no==='N/A') &&
                                                                            <td style={{width:'60px'}}>
                                                                            <p style={{border:'1px solid #d7d7d7',padding:'5px',background: '#eaeaea', color: 'white',height:'60px'}}>{sv.seat_no}</p>
                                                                            </td>
                                                                        }
                                                                        {(sv.seat_no>0) &&
                                                                            <td style={{width:'60px',cursor:'pointer'}} onClick={()=>handleClickOnSeatNo('n',index,idx,sv)}>
                                                                            <p style={{border:'1px solid #d7d7d7',padding:'5px',height:'60px'}}>
                                                                            {(sv.info && sv.info.id==undefined) &&
                                                                                    <>
                                                                                    <span  style={{background: 'green',padding: '3px',color: 'white'}}>
                                                                                        {sv.seat_no}
                                                                                    </span>
                                                                                    <p style={{    margin: '0px',
    fontSize: '9px',
    display: 'inline-block',
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',color:'blue'}}>Click here</p>
                                                                                    </>
                                                                                }
                                                                                {(sv.info && sv.info.id) &&
                                                                                    <>
                                                                                  
                                                                                    <span style={{background: '#d9d9d9',padding: '3px',color: 'white'}}>
                                                                                        {sv.seat_no}
                                                                                    </span>
                                                                                    <p style={{    margin: '0px',
    fontSize: '9px',
    display: 'inline-block',
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'}}>{sv.info.name}</p>
    <p style={{margin: '-9px 0px 0px 0px',
    fontSize: '9px'}}>{sv.info.label2}</p>
                                                                                    </>
                                                                                }
                                                                                
                                                                            </p>
                                                                            </td>
                                                                        }
                                                                        </>
                                                                    )
                                                                }))}
                                                            </tr>
                                                        )
                                                    }))}
                                                </table>
                                            </td>
                                        </>
                                        }
                                    </tr>
                                </table>
                            </div>
                        }
                    </Grid>
                }
            </Grid>
        </Box>
        
        {(is_open_allocation===true) &&
        <Modal
        open={true}
        onClose={handleCloseAllocation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4 style={{
                    margin: '0px',
                    textAlign: 'left'
                }}>Allocate Transport <Typography sx={{textTransform:'uppercase'}} variant='span'>({allocationSeatInfo.seat_type} X {allocationSeatInfo.seat_no})</Typography>
                </h4><p style={{
                    margin: '0',
                    fontSize: '12px',
                    fontFamily: 'monospace'
                }}>Select class then student to allocate the trasport</p>
                
          </Typography>
          <Typography id="modal-modal-description">
            <Grid container spacing={2}>
                
                <Grid item xs={12} sm={12} md={12} spacing={2} sx={{marginTop:'10px'}}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2}>
                    <Classes show="dropdown" className="" handleChangeData={handleSelectClass}/>
                </Grid>
                {(is_selected_class===true) &&
                    <Grid item xs={12} sm={12} md={12} spacing={2}>
                        <Autocomplete
                            value={studentInfo}
                            noOptionsText={'No Student Found ! Type To Search'}
                            onChange={(event, newValue) => handleChangeValue(newValue)}
                            onInputChange={(event, newInputValue) => handleChangeInputValue(newInputValue)}
                            disablePortal
                            id="payee_dropdown"
                            options={studentsList}
                            size="small"
                            renderInput={(params) => <TextField {...params} label={'Select Student'} />}
                        />
                    </Grid>
                }
            </Grid>
          </Typography>
          <Typography id="modal-modal-footer">
            <Grid container spacing={2}>    
                <Grid item xs={12} sm={12} md={12} spacing={2} sx={{marginTop:'10px'}}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} spacing={2} sx={{paddingTop:'10px',textAlign:'right'}}>
                    <Button onClick={handleCloseAllocation}>Close</Button>
                    <Button onClick={handleAllocateSeat} autoFocus>
                        Allocate
                    </Button>
                </Grid>
            </Grid>
                
          </Typography>
        </Box>
      </Modal>
            
        }

    </Box>
  );
}
export default React.memo(AllocateTransport);