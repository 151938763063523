import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ArrowRightAlt } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { handleChangeMenu } from "../../actions/Action";
import Classes from './classes/Classes';
import Sections from './sections/Sections';
import ClassSectionMapping from './class-section-mapping/ClassSectionMapping';
import Subjects from './subjects/Subjects';
import ClassSubjectMapping from './class-subject-mapping/ClassSubjectMapping';
import StaffTypes from './staffs/StaffTypes';
import SequenceNumber from './sequence/SequenceNumber';
import PaymentTypes from './payment-types/PaymentTypes';
import ExamTypes from './exam/ExamTypes';
import ClassRooms from './class-rooms/ClassRooms';
import Exam from './exam/Exam';
import AppUtils from '../../AppUtils';
import HolidayList from './holiday/HolidayList';
const Settings = () => {
  const state = useSelector((state) => state);
  const active_menu = state.main_menu;
  const sub_menu = state.sub_menu;
  const dispatch = useDispatch();
  const handleSelectMenu = (label,menu,sub_menu='',is_enable_add_btn=false) => {
    dispatch(handleChangeMenu(menu,sub_menu,label,is_enable_add_btn));
  }
  return (
    <Box sx={{ flexGrow: 1 }} className="MainContainer">
      <Grid container spacing={2} className="mrtop-10 mrbtm-30">
        <Grid item xs={12} sm={12} md={12} spacing={2}>
            <Typography variant='h4' sx={{color:'rgba(0, 0, 0, 0.54)',fontSize:'18px'}}>ALL SCHOOL SETTING IN ONE PLACE</Typography>
        </Grid>
        
        <Grid item xs={12} sm={12} md={4} spacing={2}>
        <Divider />
        {(AppUtils.has_access(state,'21_class_view')) &&
        <>
        <List>
          <ListItem key={'Class'} disablePadding  onClick={() => handleSelectMenu('Settings / Class','settings','class',false)} selected={(sub_menu==='class')?true:false}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowRightAlt />
              </ListItemIcon>
              <ListItemText primary={'Class'} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        </>
        }
        {(AppUtils.has_access(state,'22_section_view')) &&
<>
        <List>
          <ListItem key={'Section'} disablePadding onClick={() => handleSelectMenu('Settings / Section','settings','section',false)} selected={(sub_menu==='section')?true:false}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowRightAlt />
              </ListItemIcon>
              <ListItemText primary={'Section'} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
  </>
}
        {(AppUtils.has_access(state,'23_subject_view')) &&
<>
        <List>
          <ListItem key={'Subjects'} disablePadding onClick={() => handleSelectMenu('Settings / Subjects','settings','subjects',false)} selected={(sub_menu==='subjects')?true:false}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowRightAlt />
              </ListItemIcon>
              <ListItemText primary={'Subjects'} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
</>
}
        {(AppUtils.has_access(state,'24_staff_types_view')) &&
<>
        <List>
          <ListItem key={'staff_types'} disablePadding onClick={() => handleSelectMenu('Settings / Staff Types','settings','staff_types',false)} selected={(sub_menu==='staff_types')?true:false}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowRightAlt />
              </ListItemIcon>
              <ListItemText primary={'Staff Types'} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
</>
}
        {(AppUtils.has_access(state,'26_exam_type_view')) &&
<>
        <List>
          <ListItem key={'exam_types'} disablePadding onClick={() => handleSelectMenu('Settings / Exam Types','settings','exam_types',false)} selected={(sub_menu==='exam_types')?true:false}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowRightAlt />
              </ListItemIcon>
              <ListItemText primary={'Exam'} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
  </>
}
        {(AppUtils.has_access(state,'28_class_room_view')) &&
<>
        <List>
          <ListItem key={'class_room'} disablePadding onClick={() => handleSelectMenu('Settings / Class Room','settings','class_room',false)} selected={(sub_menu==='class_room')?true:false}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowRightAlt />
              </ListItemIcon>
              <ListItemText primary={'Class Room'} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
  </>
}
        {(AppUtils.has_access(state,'29_payment_types_view')) &&
<>
        <List>
          <ListItem key={'payment_types'} disablePadding onClick={() => handleSelectMenu('Settings / Payment Types','settings','payment_types',false)} selected={(sub_menu==='payment_types')?true:false}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowRightAlt />
              </ListItemIcon>
              <ListItemText primary={'Payment Types'} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        </>
}
        {(AppUtils.has_access(state,'30_sequence_view')) &&
        <>
        <List>
          <ListItem key={'sequence'} disablePadding onClick={() => handleSelectMenu('Settings / Sequence','settings','sequence',false)} selected={(sub_menu==='sequence')?true:false}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowRightAlt />
              </ListItemIcon>
              <ListItemText primary={'Sequence'} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        </>
        }
       
        <List>
          <ListItem key={'holiday-list'} disablePadding onClick={() => handleSelectMenu('Settings / Holiday List','settings','holiday-list',false)} selected={(sub_menu==='holiday-list')?true:false}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowRightAlt />
              </ListItemIcon>
              <ListItemText primary={'Holiday List'} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        
       {/*  <List>
          <ListItem key={'Class Section Mapping'} disablePadding onClick={() => handleSelectMenu('Settings / Class Section Mapping','settings','class_section_mapping',false)} selected={(sub_menu==='class_section_mapping')?true:false}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowRightAlt />
              </ListItemIcon>
              <ListItemText primary={'Class Section Mapping'} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider /> */}
        {/* 
        <List>
          <ListItem key={'Class Subject Mapping'} disablePadding onClick={() => handleSelectMenu('Settings / Class Subject Mapping','settings','class_subject_mapping',false)} selected={(sub_menu==='class_subject_mapping')?true:false}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowRightAlt />
              </ListItemIcon>
              <ListItemText primary={'Class Subject Mapping'} />
            </ListItemButton>
          </ListItem>
        </List> */}
        <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={8} spacing={2}>
        <Divider />
        {(AppUtils.has_access(state,'21_class_view')) &&
        <>
        {(sub_menu==='class') &&
            <Classes show="list"/>
          }
        </>
}
{(AppUtils.has_access(state,'22_section_view')) &&
<>
        {(sub_menu==='section') &&
            <Sections show="list"/>
          }
</>
}

{(sub_menu==='class_section_mapping') &&
  <ClassSectionMapping/>
}

{(AppUtils.has_access(state,'23_subject_view')) &&
<>
        {(sub_menu==='subjects') &&
            <Subjects show="list"/>
          }
</>
}
{(AppUtils.has_access(state,'24_staff_types_view')) &&
<>
        {(sub_menu==='staff_types') &&
            <StaffTypes show="list"/>
          }
  </>
}
{(AppUtils.has_access(state,'29_payment_types_view')) &&
<>
        {(sub_menu==='payment_types') &&
            <PaymentTypes show="list"/>
          }
</>
}
{(AppUtils.has_access(state,'26_exam_type_view')) &&
<>
        {(sub_menu==='exam_types') &&
            <Exam/>
          }
</>
}
{(AppUtils.has_access(state,'28_class_room_view')) &&
<>
        {(sub_menu==='class_room') &&
            <ClassRooms show="list"/>
          }
</>
}
{(AppUtils.has_access(state,'30_sequence_view')) &&
<>
        {(sub_menu==='sequence') &&
            <SequenceNumber />
          }
</>
}

        {(sub_menu==='class_subject_mapping') &&
            <ClassSubjectMapping/>
          }

        {(sub_menu==='holiday-list') &&
            <HolidayList/>
        }

        </Grid>
      </Grid>
    </Box>
  );
}

export default Settings;