import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Divider, InputLabel, MenuItem, NativeSelect, Typography } from '@mui/material';
import LoaderButton from '../LoaderButton';
import { AccountCircleRounded, BusAlert, CheckCircle, DirectionsBus } from '@mui/icons-material';
import BasicDatePicker from '../DatePicker';
import Classes from '../settings/classes/Classes';
import Sections from '../settings/sections/Sections';
import AppDropDown from '../AppDropDown';
import APP_HTTP from '../../APP_HTTP';
import { handleSetStudentInfo, handleToggleEditStudent, handleToggleViewStudent } from './Action';
import { handleChangeMenu } from '../../actions/Action';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e86ad',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#ffffff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


const CreateStudent = (props)=> {
    let studentDataInitialState = {
        update_id : 0,
        student_name : '',
        gender : 'male',
        dob : new Date(),
        father_name : '',
        mother_name : '',
        mobile_number : '',
        alt_mobile_number : '',
        email : '',
        city : '',
        state : 'Bihar',
        pincode : '',
        locality : '',
        admission_date : new Date(),
        class : '',
        class_id : 0,
        section : '',
        section_id : 0,
        fee : '',
        fee_type : 'monthly',
        status : 'active',
        aadhar_card : '',
        mother_tounge : 'hindi',
        bus_facility : 'no',
        bus_from : '',
        bus_to : '',
        prev_school_name : '',
        prev_class : '',
        prev_medium : '',
        document : [],
    }
    let [image_url,setImageURL] = React.useState('');
    let [current_sequence,setCurrentSequence] = React.useState('');
    let [isSaveClicked,setIsSaveClicked] = React.useState(false);
    let [studentData,setStudentData] = React.useState(studentDataInitialState);
    
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const initialized = React.useRef(false);
    React.useEffect(()=>{
        if (!initialized.current) {
            
            initialized.current = true;
            if(props.student_data){
                let student_data = props.student_data;
                if(student_data.is_db===undefined){
                    setStudentData(student_data);
                    setCurrentSequence(student_data.student_sequence);
                    setImageURL(student_data.photo);
                }else{
                    APP_HTTP.REQUEST('student/get_student_on_id',{id:student_data.student_id},true,true).then((response) => {
                        let resp = response.data.response;
                        setStudentData(resp);
                        setCurrentSequence(resp.student_sequence);
                        setImageURL(resp.photo);
                        dispatch(handleSetStudentInfo(resp));
                    });
                }
                //handleChangeData('gender',student_data.gender);
                //handleChangeData('gender',student_data.gender);
            }else{
                APP_HTTP.REQUEST('settings/get_current_sequence',{type:'student'},true,true).then((response) => {
                    let resp = response.data;
                    let sequence = resp.response;
                    setCurrentSequence(sequence);
                });
            }
        }
       
    },[props])

    const gender_list = [{key:'male',value:'Male'},{key:'female',value:'Female'}];
    const states = state.states;
    let state_list = [];
    for (let index = 0; index < states.length; index++) {
        const state_name = states[index];
        let list_item = {key:state_name,value:state_name};
        state_list.push(list_item);
    }

  
  const handleSaveInformation = (type) =>{
    setIsSaveClicked(true);
    let stData = {...studentData};
    let upload_file = document.getElementById("upload_file");
    let file_qs = document.querySelectorAll('input[name="documents"]');
    
    const files = upload_file.files;
    const formData = new FormData();
    let photo = files[0];

    for (let index = 0; index < file_qs.length; index++) {
        const element = file_qs[index];
        const doc_id = element.id;
        const doc_val = element.files[0];
        formData.append(doc_id,doc_val);
        if(doc_val!==undefined){
            var doc_url = URL.createObjectURL(doc_val);
            stData[doc_id] = doc_url;
        }
    }
    setStudentData(stData);
    dispatch(handleSetStudentInfo(stData));
    formData.append('photo', photo);
    formData.append('data', JSON.stringify(studentData));
   
    setStudentData({});
    //dispatch(handleSetStudentInfo({}));
    APP_HTTP.REQUEST('student/save_student',formData,true,true,true).then((response) => {
        setIsSaveClicked(false);
        let last_id = response.data.id;
        dispatch(handleChangeMenu('student','view/'+last_id,'Student Profile',false));
        dispatch(handleToggleViewStudent(true));
    });
  }
  const handleUploadFileClick = () =>{
    if(document.getElementById("upload_file")){
        document.getElementById("upload_file").click();   
    }
  }
  const handleUploadFile = (e) =>{
    const files = e.target.files;
    if(files[0]){
        var url = URL.createObjectURL(files[0]);
        setImageURL(url);
    }
  }
  const handleChangeData = (id,value) =>{
    let stuData = {...studentData};
    if(id.target && id.target.id){
        let value = id.target.value;
        let key = id.target.id;
        stuData[key] = value;
    }else{
        stuData[id] = value;
    }
    setStudentData(stuData);
  }
  const handleCancelEdit = () =>{
    dispatch(handleChangeMenu('students','','Students',true));
    dispatch(handleToggleEditStudent(false));
  }
  const handleUploadDocument = (e,doc_type) =>{
    const files = e.target.files;

  }
  return (
    <div id="create-student">
        <Box sx={{ flexGrow: 1 }} className="MainContainer">
        <Grid container spacing={2} className="mrtop-10 mrbtm-30">  
            <Grid item xs={12} sm={12} md={12} spacing={2}>
                <Grid container spacing={2} className="mrtop-10">  
                    <Grid item xs={12} sm={12} md={12} sx={{color: '#3c3c3c',padding: '5px', borderBottom:'1px solid #d7d7d7'}}>
                        <Grid container spacing={2} className="mrtop-10"> 
                            <Grid item xs={6}> 
                            {(props.student_data===undefined) &&
                                <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>Create Student</Typography>
                            }
                            {(props.student_data!==undefined) &&
                                <Typography variant='h4' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>Update Student</Typography>
                            }
                                <Typography variant='span' sx={{fontSize:'14px',color:'gray'}}>Please fill the student information in below field</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign:'right'}}> 
                                <Typography variant='span' sx={{fontSize:'20px',fontWeight:'bold',color:'gray'}}>{(current_sequence!=="")?current_sequence:''}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className="mrtop-15" style={{textAlign:'center'}}>
                        <input type='file' id="upload_file" accept="image/png,image/jpg, image/jpeg" style={{display:'none'}} onChange={handleUploadFile}/>
                        <Box sx={{height:'150px',width:'150px',border:'5px solid #d7d7d7',margin:'0 auto',borderRadius:'50%',cursor:'pointer'}} onClick={handleUploadFileClick}>
                            {(image_url!=='') &&
                                <img src={image_url} alt="Image" height={'100%'} width={'100%'} style={{borderRadius:'50%'}}/>
                            }
                            {(image_url==='') &&
                                <AccountCircleRounded size="large" sx={{height:'100%',width:'100%',color:'#d7d7d7'}}/>
                            }
                        </Box>
                    </Grid>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>Basic Information</h3>
                        <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Student Name"
                                value={studentData.student_name}
                                /* key={studentData.student_name} */
                                size="small"
                                id="student_name"
                                fullWidth
                                inputProps={{className:"student-data"}}
                                onChange={handleChangeData}
                                /* InputLabelProps={{ shrink: true }} */
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                        <AppDropDown label="Gender" id="gender" defaultValue={studentData.gender} list_item={gender_list} size="small" className="student-data" handleChangeData={handleChangeData}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            {(studentData.dob!=='' && props.student_data) &&
                                <BasicDatePicker label="Date Of Birth" size="small" id="dob" defaultValue={studentData.dob} className="student-data" handleChangeData={handleChangeData}/>
                            }
                            {(props.student_data===undefined) &&
                                <BasicDatePicker label="Date Of Birth" size="small" id="dob" className="student-data" handleChangeData={handleChangeData}/>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Father's Name"
                                onChange={handleChangeData}
                                size="small"
                                id="father_name"
                                fullWidth
                                inputProps={{className:"student-data"}}
                                value={studentData.father_name}
                                /* key={studentData.father_name} */
                                />
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Mother's Name"
                                onChange={handleChangeData}
                                size="small"
                                id="mother_name"
                                fullWidth
                                inputProps={{className:"student-data"}}
                                value={studentData.mother_name}
                                /* key={studentData.mother_name} */
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Mobile Number"
                                onChange={handleChangeData}
                                size="small"
                                id="mobile_number"
                                fullWidth
                                inputProps={{className:"student-data"}}
                                value={studentData.mobile_number}
                                /* key={studentData.mobile_number} */
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Alternate Mobile Number"
                                onChange={handleChangeData}
                                size="small"
                                id="alt_mobile_number"
                                fullWidth
                                inputProps={{className:"student-data"}}
                                value={studentData.alt_mobile_number}
                                /* key={studentData.alt_mobile_number} */
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Email ID"
                                onChange={handleChangeData}
                                size="small"
                                id="email"
                                fullWidth
                                inputProps={{className:"student-data"}}
                                value={studentData.email}
                                /* key={studentData.email} */
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="City"
                                onChange={handleChangeData}
                                size="small"
                                id="city"
                                fullWidth
                                inputProps={{className:"student-data"}}
                                value={studentData.city}
                                /* key={studentData.city} */
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <AppDropDown label="State" id="state" defaultValue={studentData.state} list_item={state_list} size="small" className="student-data" handleChangeData={handleChangeData}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Pincode"
                                onChange={handleChangeData}
                                size="small"
                                id="pincode"
                                fullWidth
                                inputProps={{className:"student-data"}}
                                value={studentData.pincode}
                                /* key={studentData.pincode} */
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                                label="Locality"
                                onChange={handleChangeData}
                                size="small"
                                id="locality"
                                fullWidth
                                inputProps={{className:"student-data"}}
                                value={studentData.locality}
                                /* key={studentData.locality} */
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>Advanced Information</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <TextField
                            onChange={handleChangeData}
                            label="Aadhar Card No"
                            id="aadhar_card"
                            fullWidth
                            size='small'
                            inputProps={{className:"student-data"}}
                            value={studentData.aadhar_card}
                            /* key={studentData.aadhar_card} */
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                        <AppDropDown label="Mother Tounge" id="mother_tounge" defaultValue={studentData.mother_tounge} list_item={[{key:'hindi',value:'Hindi'},{key:'urdu',value:'Urdu'},{key:'english',value:'English'},{key:'bhojpuri',value:'Bhojpuri'},{key:'bengoli',value:'Bengoli'}]} size="small" className="student-data" handleChangeData={handleChangeData}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            {(studentData.admission_date!=='' && props.student_data) &&
                               <BasicDatePicker label="Admission Date" size="small" className="student-data" id="admission_date" handleChangeData={handleChangeData} defaultValue={studentData.admission_date}/>
                            }
                            {(props.student_data===undefined) &&
                                <BasicDatePicker label="Admission Date" size="small" className="student-data" id="admission_date" handleChangeData={handleChangeData}/>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                        {(studentData.class_id!=='' && studentData.class_id>0 &&  props.student_data) &&
                            <Classes show="dropdown" defaultValue={studentData.class_id} className="student-data" handleChangeData={handleChangeData}/>
                        }
                         {(props.student_data===undefined) &&
                            <Classes show="dropdown" className="student-data" handleChangeData={handleChangeData}/>
                        }
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                        {(studentData.section_id!=='' && studentData.section_id>0 && props.student_data) &&
                             <Sections show="dropdown" className="student-data" defaultValue={studentData.section_id} handleChangeData={handleChangeData}/>
                        }
                        {(props.student_data===undefined) &&
                            <Sections show="dropdown" className="student-data" handleChangeData={handleChangeData}/>
                        }
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            <TextField
                            onChange={handleChangeData}
                            label="Fee Amount"
                            id="fee"
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                            }}
                            size='small'
                            inputProps={{className:"student-data"}}
                            value={studentData.fee}
                            /* key={studentData.fee} */
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            {(props.student_data) &&
                                <AppDropDown label="Fee Type" id="fee_type" defaultValue={studentData.fee_type}  list_item={[{key:'monthly',value:'Monthly'},{key:'quarterly',value:'Quarterly'},{key:'semester',value:'Semester'},{key:'yearly',value:'Yearly'}]} size="small" className="student-data" handleChangeData={handleChangeData}/>
                            }
                            {(props.student_data===undefined) &&
                                <AppDropDown label="Fee Type" id="fee_type" defaultValue={studentData.fee_type}  list_item={[{key:'monthly',value:'Monthly'},{key:'quarterly',value:'Quarterly'},{key:'semester',value:'Semester'},{key:'yearly',value:'Yearly'}]} size="small" className="student-data" handleChangeData={handleChangeData}/>
                            }
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            {(props.student_data) &&
                                <AppDropDown label="Status" id="status" defaultValue={studentData.status} list_item={[{key:'active',value:'Active'},{key:'inactive',value:'Inactive'}]} size="small" className="student-data" handleChangeData={handleChangeData}/>
                            }
                            {(props.student_data===undefined) &&
                                <AppDropDown label="Status" id="status" defaultValue={studentData.status} list_item={[{key:'active',value:'Active'},{key:'inactive',value:'Inactive'}]} size="small" className="student-data" handleChangeData={handleChangeData}/>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>Transportation</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                            {(props.student_data!==undefined) &&
                                <AppDropDown label="Bus Facility" id="bus_facility" defaultValue={studentData.bus_facility}  list_item={[{key:'no',value:'No'},{key:'yes',value:'Yes'}]} size="small" className="student-data" handleChangeData={handleChangeData}/>
                            }
                            {(props.student_data===undefined) &&
                                <AppDropDown label="Bus Facility" id="bus_facility" defaultValue={studentData.bus_facility}  list_item={[{key:'no',value:'No'},{key:'yes',value:'Yes'}]} size="small" className="student-data" handleChangeData={handleChangeData}/>
                            }
                        </Grid>
                        {(studentData.bus_facility==='yes') &&
                        <>
                            <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                                <TextField
                                onChange={handleChangeData}
                                label="Bus From"
                                id="bus_from"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><DirectionsBus/></InputAdornment>,
                                }}
                                size='small'
                                inputProps={{className:"student-data"}}
                                value={studentData.bus_from}
                                /* key={studentData.bus_from} */
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} className="mrtop-15">
                                <TextField
                                onChange={handleChangeData}
                                label="Bus To"
                                id="bus_to"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><DirectionsBus/></InputAdornment>,
                                }}
                                size='small'
                                inputProps={{className:"student-data"}}
                                value={studentData.bus_to}
                                /* key={studentData.bus_to} */
                                />
                            </Grid>
                        </>
                        }
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>School in he/she was studying</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <TextField
                            onChange={handleChangeData}
                            label="School Name"
                            id="prev_school_name"
                            fullWidth
                            size='small'
                            inputProps={{className:"student-data"}}
                            value={studentData.prev_school_name}
                            /* key={studentData.prev_school_name} */
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <TextField
                            onChange={handleChangeData}
                            label="Class"
                            id="prev_class"
                            fullWidth
                            size='small'
                            inputProps={{className:"student-data"}}
                            value={studentData.prev_class}
                            /* key={studentData.prev_class} */
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <TextField
                            onChange={handleChangeData}
                            label="Medium"
                            id="prev_medium"
                            fullWidth
                            size='small'
                            inputProps={{className:"student-data"}}
                            value={studentData.prev_medium}
                            /* key={studentData.prev_medium} */
                            />
                        </Grid>
                       
                        <Grid item xs={12} sm={12} md={12} className="mrtop-15">
                            <h3>Documents</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className='mrtop-15'>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(studentData.aadharc && studentData.aadharc!='') &&
                                                    <CheckCircle sx={{fontSize:'14px',color:'green'}}/>
                                                }
                                                <Typography variant="span" sx={{marginLeft:'5px'}}>Aadhar Card </Typography>
                                            </StyledTableCell>      
                                            <StyledTableCell>
                                                <input type='file' accept="image/png,image/jpg, image/jpeg" name="documents" id="aadharc" onChange={(e)=>handleUploadDocument(e,'aadhar')}/></StyledTableCell>      
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(studentData.dobc && studentData.dobc!='') &&
                                                    <CheckCircle sx={{fontSize:'14px',color:'green'}}/>
                                                }
                                                <Typography variant="span" sx={{marginLeft:'5px'}}>Date of Birth Certificate</Typography>
                                            </StyledTableCell>      
                                            <StyledTableCell>
                                            <input type='file' name="documents" id="dobc" accept="image/png,image/jpg, image/jpeg" onChange={(e)=>handleUploadDocument(e,'dob')}/>
                                            </StyledTableCell>      
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(studentData.tcc && studentData.tcc!='') &&
                                                    <CheckCircle sx={{fontSize:'14px',color:'green'}}/>
                                                }
                                                <Typography variant="span" sx={{marginLeft:'5px'}}>TC/SLC</Typography>
                                            </StyledTableCell>      
                                            <StyledTableCell>
                                            <input type='file' name="documents" id="tcc" accept="image/png,image/jpg, image/jpeg" onChange={(e)=>handleUploadDocument(e,'tc')}/>
                                            </StyledTableCell>      
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(studentData.mark_sheetc && studentData.mark_sheetc!='') &&
                                                    <CheckCircle sx={{fontSize:'14px',color:'green'}}/>
                                                }
                                                <Typography variant="span" sx={{marginLeft:'5px'}}>Passing Certificate / Mark Sheet</Typography>
                                            </StyledTableCell>      
                                            <StyledTableCell>
                                            <input type='file' name="documents" id="mark_sheetc" accept="image/png,image/jpg, image/jpeg" onChange={(e)=>handleUploadDocument(e,'mark_sheet')}/>
                                            </StyledTableCell>      
                                        </StyledTableRow>    
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                {(studentData.characterc && studentData.characterc!='') &&
                                                    <CheckCircle sx={{fontSize:'14px',color:'green'}}/>
                                                }
                                                Character Certificate
                                            </StyledTableCell>      
                                            <StyledTableCell>
                                            <input type='file' name="documents" accept="image/png,image/jpg, image/jpeg" id="characterc" onChange={(e)=>handleUploadDocument(e,'character')}/>
                                            </StyledTableCell>      
                                        </StyledTableRow>    
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} spacing={2} className="mrtop-30 footer-btn-blk">
            <Grid xs={6}>
                {(props.student_data) &&
                <Button variant="outlined" onClick={handleCancelEdit}>Cancel</Button>
                }
            </Grid>
            <Grid xs={6} sx={{textAlign:'right'}}>
            {(isSaveClicked===false) &&
                <Button variant="contained" onClick={() => handleSaveInformation('save')}>Save Information</Button>
            }
            {(isSaveClicked===true) &&
                <LoaderButton label="Save Information"/>
            }
            </Grid>
        </Grid>
        
        </Box>
    </div>
  );
}
export default CreateStudent;